import { Address, Company, DraftAccount, Life, PaymentDetails, Plan, PlanBenefit, Policy, SubscriptionRequest, User } from '@peachy/core-domain-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { isBefore, startOfToday, subYears } from 'date-fns'
import { createStore, SetStoreFunction, Store } from 'solid-js/store'
import * as planConfigService from '../../../components/PlanBuilder/domain/plan-utils'
import { mapToPlanBenefit } from '../../../components/PlanBuilder/domain/plan-model-mapper'
import { validatePolicy } from '../../../service/validation/ValidationService'
import { createSubscriptionRequest } from '../create-subscription-requests'
import { SubscriptionRequestRepository } from '../../../service/subscription/SubscriptionRequestRepository'

/**
 * Stores and manages all the details relating to the SubscriptionRequest object
 */
export class SubscriptionRequestStore {
    private subscriptionRequest: Store<Draft<SubscriptionRequest>>
    private storeSubscriptionRequest: SetStoreFunction<Draft<SubscriptionRequest>>

    constructor (private readonly repository: SubscriptionRequestRepository) {
        [this.subscriptionRequest, this.storeSubscriptionRequest] = createStore<Draft<SubscriptionRequest>>(repository.get() ?? createSubscriptionRequest())
    }

    public getSubscriptionRequest() {
        return this.subscriptionRequest
    }

    public getPolicies() {
        return this.subscriptionRequest.policies
    }

    public getSubscriptionStartDate() {
        return this.subscriptionRequest.subscription.startDate
    }

    ///// user details

    public updateUserName(firstName: string, lastName: string) {
        const fullName = `${firstName} ${lastName}`
        this.storeSubscriptionRequest('account', 'user', 'firstname', firstName)
        this.storeSubscriptionRequest('account', 'user', 'lastname', lastName)
        this.storeSubscriptionRequest('account', 'contactName', fullName)
        this.storeSubscriptionRequest('subscription', 'contactName', fullName)
        this.save()
    }

    public updateCompany(company: Company) {
        this.storeSubscriptionRequest('account', 'company', company)
        this.storeSubscriptionRequest('account', 'name', company.name)
        this.storeSubscriptionRequest('subscription', 'name', company.name)
        this.save()
    }

    public updateEmail(email: string) {
        this.storeSubscriptionRequest('account', 'user', 'email', email)
        this.storeSubscriptionRequest('account', 'contactEmail', email)
        this.storeSubscriptionRequest('subscription', 'contactEmail', email)
        this.save()
    }

    public updateUserCognitoId(cognitoUserId: string) {
        this.storeSubscriptionRequest('account', 'user', 'cognitoUserId', cognitoUserId)
        this.save()
    }

    public updateMarketingAccepted(marketingAccepted: boolean) {
        this.storeSubscriptionRequest('account', 'user', 'marketingAccepted', marketingAccepted)
        this.save()
    }

    public updatePoliciesAccepted(policiesAccepted: boolean) {
        this.storeSubscriptionRequest('account', 'user', 'policiesAccepted', policiesAccepted)
        this.save()
    }

    public getUser(): User {
        return this.subscriptionRequest.account.user as User
    }

    public getAccount(): DraftAccount {
        return this.subscriptionRequest.account as DraftAccount
    }

    public getCompany(): Company {
        return this.subscriptionRequest.account.company as Company
    }

    ///// policies

    public addPolicy (newPolicy: Draft<Policy>) {
        this.storeSubscriptionRequest('policies', (prev) => [...prev, newPolicy])
        this.save()
    }

    public updateLife (policyIndex: number, lifeId: string, fields: Draft<Life>) {
        this.storeSubscriptionRequest('policies', policyIndex, 'lives', lifeId, fields)
        this.save()
    }

    public removeLife(policyIndex: number, lifeId: string) {
        this.updateLife(policyIndex, lifeId, undefined)
    }

    public removePolicy (removeIndex: number) {
        this.storeSubscriptionRequest('policies', (polices) => polices.filter((v, index) => removeIndex != index))
        this.save()
    }

    public isValidPolicies = () => this.getPolicies().every(_ => validatePolicy(_))

    public getPolicyForLife = (lifeId: string): Draft<Policy> => {
        return this.subscriptionRequest.policies.find(p => !!p.lives[lifeId])
    }

    public getPolicyIndexForLife = (lifeId: string): number => {
        return this.subscriptionRequest.policies.findIndex(p => !!p.lives[lifeId])
    }

    public getLives() {
        return this.subscriptionRequest.policies.map(p => Object.values(p.lives)).flat()
    }

    public getLivesForPlan(planId?: string) {
        return this.getLives().filter(l => l.planId === planId)
    }

    public hasSomeLifeForPlan(planId?: string) {
        return this.getLives().some(life => life.planId === planId)
    }

    public getOldestDateOfBirthForLives(youngestAge: number, filter: (life: Draft<Life>) => boolean) {
        return this.getLives().filter(filter).reduce((oldestDate, life) => {
            const birthDate = new Date(life.dateOfBirth)

            return (isBefore(birthDate, oldestDate) ? birthDate : oldestDate)
        }, subYears(startOfToday(), youngestAge))
    }

    public updateStartDate(startDate: number) {
        this.storeSubscriptionRequest('subscription', 'startDate', startDate)
        this.subscriptionRequest.policies.forEach((p, i) => {
            this.storeSubscriptionRequest('policies', i, 'startDate', startDate)
        })
        this.save()
    }

    public getPlans () {
        return this.subscriptionRequest.plans as Plan[]
    }    
    
    ///// benefits

    // benefitId that is passed in is the group benefit e.g. DENTAL_OPTICAL
    public addBenefit (planId: string, benefitId: string) {
        if (this.hasBenefit(planId, benefitId)) {
            return
        }

        const plan = this.getPlanById(planId)
        const benefitToAdd = planConfigService.getBenefitForPlan(plan.configId, benefitId)
        const newBenefits = mapToPlanBenefit(benefitToAdd)
        this.updatePlanBenefits(planId, [...plan.benefits, ...newBenefits])
        this.save()
    }

    // benefitId that is passed in is the group benefit e.g. DENTAL_OPTICAL
    public removeBenefit (planId: string, benefitId: string) {
        const plan = this.getPlanById(planId)
        const benefitTypes = planConfigService.getBenefitTypesForPlan(plan.configId, benefitId)
        const remainingBenefits = plan.benefits.filter(benefit => !benefitTypes.includes(benefit.type))
        this.updatePlanBenefits(planId, remainingBenefits)
        this.save()
    }

    public updateBenefitLimit (planId: string, benefitId: string, limit: number) {
        const plan = this.getPlanById(planId)
        const benefitTypes = planConfigService.getBenefitTypesForPlan(plan.configId, benefitId)

        const updatedBenefits = plan.benefits.map(benefit => ({
            ...benefit,
            limit: (benefitTypes.includes(benefit.type)) ? limit : benefit.limit
        }))

        this.updatePlanBenefits(planId, updatedBenefits)
        this.save()
    }

    public getBenefitLimit (planId: string, benefitId: string): number {
        return this.getBenefit(planId, benefitId).limit
    }

    public getBenefit (planId: string, benefitId: string): PlanBenefit | undefined {
        const plan = this.getPlanById(planId)
        const benefitTypes = planConfigService.getBenefitTypesForPlan(plan.configId, benefitId)
        return plan?.benefits.find(planBenefit => planBenefit.type == benefitTypes[0])
    }

    public getExcess(planId: string): number | undefined {
        const plan = this.getPlanById(planId)
        return plan.excess?.amountInPence
    }

    public updatePlanExcess (planId: string, excess: number) {
        this.storeSubscriptionRequest('plans', this.getPlanIndex(planId), 'excess', 'amountInPence', excess)
        this.save()
    }


    ///// payment

    public updatePayment(paymentDetails: Draft<PaymentDetails>) {
        this.storeSubscriptionRequest('subscription', 'paymentDetails', paymentDetails)
    }

    public updateBillingAddress(hasSeparateAddress: boolean, address: Draft<Address>) {
        this.storeSubscriptionRequest('subscription', 'paymentDetails', 'billingAddress', address)
        this.storeSubscriptionRequest('subscription', 'paymentDetails', 'hasSeparateBillingAddress', hasSeparateAddress)
    }

    public getPaymentDetails() {
        return this.subscriptionRequest.subscription.paymentDetails
    }

    private getPlanIndex (planId: string) {
        return this.subscriptionRequest.plans.findIndex(p => p.id === planId)
    }

    private getPlanById (planId: string) {
        return this.getPlans().find(plan => plan.id === planId)
    }

    private hasBenefit (planId: string, benefitId: string) {
        const benefit = this.getBenefit(planId, benefitId)
        return Boolean(benefit)
    }

    private updatePlanBenefits (planId: string, benefits: PlanBenefit[]) {
        this.storeSubscriptionRequest('plans', this.getPlanIndex(planId), 'benefits', benefits)
    }

    private save() {
        this.repository.save(this.getSubscriptionRequest())
    }
}

