import { Life, Policy, toClass } from '@peachy/core-domain-pure'
import { Draft, formatDmyDate } from '@peachy/utility-kit-pure'
import { sortBy, startCase } from 'lodash-es'
import { Component, For, Show } from 'solid-js'
import { getPolicies, getPrimaryLives } from '../../../components/Home/HomeProvider'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { getPrimaryLife } from '../../../providers/AccountSubscription/stores/AccountSubscriptionStore'
import { MemberTextDisplay } from '../panels/YourPlanInfoPanels/YourPlanPolicyDetailsPanel'
import styles from './Summary.module.css'

export default () => {
    const store = useStore()

    const currentLives = getPolicies()

    const removedLives = store.getDeletedLives()
    const removedPrimaryLives = removedLives.filter(l => l.type === 'PRIMARY')
    const removedNonPrimaryLives = removedLives.filter(l => l.type !== 'PRIMARY')

    const removedPolicies = sortBy(currentLives.filter(p => removedPrimaryLives.find(l => p.lives[l.id])), [
        p => p.getPrimaryLife().lastname,
        p => p.getPrimaryLife().firstname,
        p => p.getPrimaryLife().dateOfBirth
    ])

    const removedDependantsWithPolicyRemoved = removedPolicies.flatMap(p => p.getNonPrimaryLives())
    const removedDependantsWithExistingPolicy = sortBy(removedNonPrimaryLives
        .filter(l => !removedDependantsWithPolicyRemoved.find(rl => rl.id === l.id)),
        [
            l => l.lastname,
            l => l.firstname,
            l => l.dateOfBirth
        ])

    const addedPolicies = sortBy(store.getPolicies()
        .filter(policy => !getPrimaryLives().find(cl => cl.id === getPrimaryLife(policy).id))
        .map(p => toClass(p, Policy)), [
        p => p.getPrimaryLife().lastname,
        p => p.getPrimaryLife().firstname,
        p => p.getPrimaryLife().dateOfBirth
    ])

    const activePolicies = currentLives.filter(p => !removedPrimaryLives.find(rpl => rpl.id === p.getPrimaryLife().id))
    const activeDependants = activePolicies.flatMap(p => Object.values(p.lives).filter(l => l.type !== 'PRIMARY'))

    const activePolicyInStore = store.getPolicies().filter(p => activePolicies.find(cp => cp.id === p.id))
    const addedDependantsOnActivePolicies = sortBy(activePolicyInStore.flatMap(p =>
        Object.values(p.lives).filter(l => l.type !== 'PRIMARY' && !activeDependants.find(ad => ad.id === l.id))), [
        l => l.lastname,
        l => l.firstname,
        l => l.dateOfBirth
    ])

    const totalMemberAdded = addedPolicies.flatMap(p => Object.values(p.lives)).length + addedDependantsOnActivePolicies.length
    const totalMemberRemoved = removedPolicies.flatMap(p => Object.values(p.lives)).length + removedDependantsWithExistingPolicy.length

    return (
        <div class={styles.SummaryChanges}>
            <Show when={totalMemberAdded}>
                <p>You have added <b>{totalMemberAdded}</b> <MemberTextDisplay noOfMembers={totalMemberAdded} /></p>
                <ul>
                    <For each={addedPolicies}>
                        {(policy) => <PolicyRow policy={policy} planAction='ADD' />}
                    </For>
                    <For each={addedDependantsOnActivePolicies}>
                        {(life) => <DependantRow life={life} planAction='ADD' />}
                    </For>
                </ul>
            </Show>
            <Show when={totalMemberRemoved}>
                <p>You have removed <b>{totalMemberRemoved}</b> <MemberTextDisplay noOfMembers={totalMemberRemoved} /></p>
                <ul>
                    <For each={removedPolicies}>
                        {(policy) => <PolicyRow policy={policy} planAction='REMOVE' />}
                    </For>
                    <For each={removedDependantsWithExistingPolicy}>
                        {(life) => <DependantRow life={life} planAction='REMOVE' />}
                    </For>
                </ul>
            </Show>
        </div>
    )
}

const PolicyRow: Component<{ policy: Policy, planAction: PlanAction }> = (props) => {
    const store = useStore()
    const primaryLife = props.policy.getPrimaryLife()

    const secondLife = props.policy.getSecondaryLife()
    const dependants = props.policy.getDependents()

    const plan = store.getPlanById(primaryLife.planId)?.name

    return (
        <>
            <LifeRow life={primaryLife} planAction={props.planAction} plan={plan}></LifeRow>
            <Show when={secondLife}>
                <LifeRow life={secondLife} primaryLifeName={primaryLife.fullName()} planAction={props.planAction} plan={plan}></LifeRow>
            </Show>
            <Show when={dependants}>
                <For each={dependants}>
                    {(dependant) => <LifeRow life={dependant} primaryLifeName={primaryLife.fullName()} planAction={props.planAction} plan={plan}></LifeRow>}
                </For>
            </Show>
        </>
    )
}

const LifeRow: Component<{ life: Life, primaryLifeName?: string, planAction: PlanAction, plan: string }> = (props) => {
    const life = props.life
    const primaryLife = () => <Show when={props.primaryLifeName}>
        <>[{props.primaryLifeName}], </>
    </Show>

    return (<li>{life.fullName()} {primaryLife()}{formatDmyDate(life.dateOfBirth)}, {life.address.postcode} {planActionText(props.planAction)} {startCase(props.plan)}</li>)
}

const DependantRow: Component<{ life: Draft<Life> | Life, planAction: PlanAction }> = (props) => {
    const store = useStore()

    const life = props.life instanceof Life ? props.life : toClass(props.life, Life)
    const policy = () => {
        switch (props.planAction) {
            case 'ADD': return store.getPolicyForLife(life.id)
            // Life has been removed from policy in store so cannot traceback
            case 'REMOVE': return getPolicies().find(p => p.getNonPrimaryLives().find(npl => npl.id === life.id))
            default: return
        }
    }

    const primaryLife = toClass(policy(), Policy).getPrimaryLife()
    const plan = store.getPlanById(primaryLife.planId)?.name

    return (<LifeRow life={life} primaryLifeName={primaryLife.fullName()} planAction={props.planAction} plan={plan} />)
}

type PlanAction = 'ADD' | 'REMOVE'

const planActionText = (action: PlanAction) => {
    switch (action) {
        case 'ADD': return 'to'
        case 'REMOVE': return 'from'
        default: return ''
    }
}