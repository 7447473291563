import {enumerate} from '@peachy/utility-kit-pure'
import {BenefitType, BenefitTypes} from './BenefitType'


export const ExcessDiscounts = enumerate([
    'HOSPITAL_CARE_EXCESS_DISCOUNT',
    'CONSULTATIONS_DIAGNOSTICS_EXCESS_DISCOUNT',
    'THERAPIES_EXCESS_DISCOUNT',
    'OVERALL_EXCESS_DISCOUNT'
] as const)

export type ExcessDiscount = keyof typeof ExcessDiscounts

export const excessDiscountNameFor = (benefitType: BenefitType) => {
    switch(benefitType) {
        case BenefitTypes.HOSPITAL_CARE: return ExcessDiscounts.HOSPITAL_CARE_EXCESS_DISCOUNT
        case BenefitTypes.CONSULTATIONS_DIAGNOSTICS: return ExcessDiscounts.CONSULTATIONS_DIAGNOSTICS_EXCESS_DISCOUNT
        case BenefitTypes.THERAPIES: return ExcessDiscounts.THERAPIES_EXCESS_DISCOUNT
        default: return null
    }
}