// TODO: rename!

import { createStore, SetStoreFunction, Store } from 'solid-js/store'
import { Life } from '@peachy/core-domain-pure'

export type ViewMode = 'PORTAL' | 'QUOTE'

export type OtherProperties = {
    isEditing: boolean
    isFullValidation: boolean
    isPlanEditable: boolean
    viewMode: ViewMode
    lockedLives: Life[]
}

export class OtherPropertiesStore {
    private properties: Store<OtherProperties>
    private storeProperties: SetStoreFunction<OtherProperties>

    constructor(
        private readonly plansInSelector: string[]
    ) {
        [this.properties, this.storeProperties] = createStore<OtherProperties>(
            {
                isEditing: false,
                isFullValidation: false,
                isPlanEditable: false,
                viewMode: 'QUOTE',
                lockedLives: []
            }
        )
    }

    public getPlansInSelector () {
        return this.plansInSelector
    }

    public getIsEditing (): boolean {
        return this.properties.isEditing
    }

    public setIsEditing (value: boolean) {
        this.storeProperties('isEditing', value)
    }

    public getIsFullValidation (): boolean {
        return this.properties.isFullValidation
    }

    public setIsFullValidation (value: boolean) {
        this.storeProperties('isFullValidation', value)
    }

    public getIsPlanEditable (): boolean {
        return this.properties.isPlanEditable
    }

    public setIsPlanEditable (value: boolean) {
        this.storeProperties('isPlanEditable', value)
    }

    public getViewMode (): ViewMode {
        return this.properties.viewMode
    }

    public setViewMode (viewMode: ViewMode) {
        this.storeProperties('viewMode', viewMode)
    }

    public getLockedLives (): Life[] {
        return this.properties.lockedLives
    }

    public setLockedLives (lives: Life[]) {
        this.storeProperties('lockedLives', lives)
    }

    public getLockedLivesForPlan (planId: string) {
        return this.getLockedLives()?.filter((life: Life) => life.planId === planId)
    }
}