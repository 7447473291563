import { API } from '@aws-amplify/api'
import { InvitePortalUserErrorResponse, InvitePortalUserRequest, InvitePortalUserResponse } from '@peachy/iam-sme-pure'

export class SmeUserService {
    constructor(readonly api: typeof API) { }

    async invite(body: InvitePortalUserRequest): Promise<InvitePortalUserResponse | InvitePortalUserErrorResponse> {
        const httpRequest = {
            headers: {},
            body
        }
        return this.api.post(apiName, `/invite-user`, httpRequest)
    }

    async reinvite(email: string): Promise<InvitePortalUserResponse | InvitePortalUserErrorResponse> {
        const httpRequest = {
            headers: {},
            body: {
                email
            } as InvitePortalUserRequest,
        }
        return this.api.post(apiName, `/reinvite-user`, httpRequest)
    }
}

const apiName = 'IamSmeApi'