import {enumerate} from '@peachy/utility-kit-pure'

export const BenefitTypes = enumerate([
    'HOSPITAL_CARE',
    'CONSULTATIONS_DIAGNOSTICS',
    'MENTAL_HEALTH',
    'THERAPIES',
    'DENTAL_OPTICAL',
    'REMOTE_CARE',
    'ADVICE_COUNSELLING',
] as const)

export type BenefitType = keyof typeof BenefitTypes

export type BenefitValues = {
    [_ in BenefitType]?: number
}

export type BenefitOverrides = {
    [_ in BenefitType]?: {
        coverStartDate?: string
        renewalPeriodMonths?: number
    }
}

export const BENEFITS_WITH_LIMITS: BenefitType[] = [BenefitTypes.HOSPITAL_CARE, BenefitTypes.CONSULTATIONS_DIAGNOSTICS, BenefitTypes.THERAPIES]

export const BENEFITS_WITH_EXCESS: BenefitType[] = [BenefitTypes.HOSPITAL_CARE, BenefitTypes.CONSULTATIONS_DIAGNOSTICS, BenefitTypes.THERAPIES]

export const FIXED_PRICE_BENEFITS: BenefitType[] = [BenefitTypes.DENTAL_OPTICAL, BenefitTypes.REMOTE_CARE, BenefitTypes.ADVICE_COUNSELLING]