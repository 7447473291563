import { Show, createContext, createResource, useContext } from 'solid-js'
import {AppServices, createAppServices} from '../service/AppServices'

const AppServiceContext = createContext<AppServices>()

/**
 * Sets up the services used in the app (e.g. IamService)
 * 
 * @param props 
 * @returns 
 */
export const AppServiceProvider = (props) => {
    const [appServices] = createResource(createAppServices)

    return (
        <Show when={appServices()}>
            <AppServiceContext.Provider value={ appServices() }>
                {props.children}
            </AppServiceContext.Provider>
        </Show>
    )
}

export function useServices() {
    return useContext(AppServiceContext)
}

export function useIamService() {
    return useServices()?.iamService
}

export function usePaymentsService() {
    return useServices()?.paymentsService
}

export function useSubscriptionService() {
    return useServices()?.subscriptionService
}

export function useSmeUserService() {
    return useServices()?.smeUserService
}

export function useDocGenService() {
    return useServices()?.docGenService
}