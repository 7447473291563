import {Api, defineApi, postOf} from '@peachy/core-domain-pure'
import {QuoteRequest, QuoteResponse} from '@peachy/quote-pure'

export const QuoteApiDefinition = defineApi('LambdaQuoteApi')
    .withActions({
        getQuote: postOf(QuoteRequest).fromAnyone().willReturn(QuoteResponse)
    })


export type QuoteApi = Api<typeof QuoteApiDefinition>

