import styles from './BenefitIcons.module.css'

export default function () {

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.82 75.96">
            <path class={styles.line} d="m47.45,50.84c-7.19,0-12.41-5.41-12.41-12.86s5.22-12.86,12.41-12.86,12.33,5.41,12.33,12.86-5.19,12.86-12.33,12.86Zm0-24.72c-6.61,0-11.41,4.99-11.41,11.86s4.8,11.86,11.41,11.86,11.33-4.88,11.33-11.86-4.66-11.86-11.33-11.86Z" />
            <path class={styles.line} d="m47.45,44.67c-2.24,0-4.36-.8-5.97-2.25-.21-.18-.22-.5-.04-.71.19-.21.5-.22.71-.04,1.43,1.29,3.32,2,5.3,2,2.09,0,4.04-.77,5.49-2.17.2-.19.52-.18.71.01.19.2.19.52-.01.71-1.64,1.58-3.84,2.45-6.19,2.45Z" />
            <path class={styles.line} d="m45.94,41.55c-.13,0-.26-.05-.36-.15-.11-.11-.16-.26-.14-.41l1.19-9.2c.04-.27.29-.47.56-.43.27.04.47.29.43.56l-1.11,8.58,3.05-.29c.26-.02.52.18.54.45.03.28-.18.52-.45.54l-3.67.34s-.03,0-.05,0Z" />
            <path class={styles.line} d="m39.73,35.18c-.13,0-.25-.05-.35-.14-.2-.19-.2-.51,0-.71.54-.55,1.46-.87,2.47-.87.95,0,1.8.28,2.35.77.21.18.23.5.04.71-.19.21-.5.23-.71.04-.36-.32-1.01-.52-1.69-.52-.72,0-1.41.22-1.75.57-.1.1-.23.15-.36.15Z" />
            <path class={styles.line} d="m50.67,35.18c-.13,0-.25-.05-.35-.14-.2-.19-.2-.51,0-.71.54-.55,1.46-.87,2.47-.87.95,0,1.8.28,2.36.77.21.18.23.5.04.71-.19.21-.5.23-.71.04-.74-.66-2.78-.62-3.45.06-.1.1-.23.15-.36.15Z" />
            <g>
                <g>
                    <line class={styles.line} x1="47.41" y1="16.68" x2="47.41" y2="23.17" />
                    <path class={styles.line} d="m47.41,23.67c-.28,0-.5-.22-.5-.5v-6.5c0-.28.22-.5.5-.5s.5.22.5.5v6.5c0,.28-.22.5-.5.5Z" />
                </g>
                <g>
                    <line class={styles.line} x1="37.55" y1="18.79" x2="40.68" y2="24.88" />
                    <path class={styles.line} d="m40.68,25.38c-.18,0-.36-.1-.44-.27l-3.13-6.09c-.13-.25-.03-.55.22-.67.25-.12.55-.03.67.22l3.13,6.09c.13.25.03.55-.22.67-.07.04-.15.06-.23.06Z" />
                </g>
                <g>
                    <line class={styles.line} x1="29.65" y1="24.7" x2="35.75" y2="29.26" />
                    <path class={styles.line} d="m35.75,29.76c-.1,0-.21-.03-.3-.1l-6.11-4.56c-.22-.17-.27-.48-.1-.7.17-.22.48-.27.7-.1l6.11,4.56c.22.17.27.48.1.7-.1.13-.25.2-.4.2Z" />
                </g>
                <g>
                    <line class={styles.line} x1="33.28" y1="34.96" x2="25.26" y2="33.24" />
                    <path class={styles.line} d="m33.28,35.46s-.07,0-.11-.01l-8.01-1.72c-.27-.06-.44-.32-.38-.59.06-.27.33-.44.59-.38l8.01,1.72c.27.06.44.32.38.59-.05.24-.26.4-.49.4Z" />
                </g>
                <g>
                    <line class={styles.line} x1="25.26" y1="42.72" x2="33.28" y2="41" />
                    <path class={styles.line} d="m25.26,43.22c-.23,0-.44-.16-.49-.4-.06-.27.11-.54.38-.59l8.01-1.71c.27-.05.54.11.59.38.06.27-.11.54-.38.59l-8.01,1.71s-.07.01-.11.01Z" />
                </g>
                <g>
                    <line class={styles.line} x1="29.65" y1="51.26" x2="35.75" y2="46.69" />
                    <path class={styles.line} d="m29.65,51.76c-.15,0-.3-.07-.4-.2-.17-.22-.12-.54.1-.7l6.11-4.57c.22-.17.54-.12.7.1.17.22.12.54-.1.7l-6.11,4.57c-.09.07-.2.1-.3.1Z" />
                </g>
                <g>
                    <line class={styles.line} x1="37.55" y1="57.17" x2="40.68" y2="51.08" />
                    <path class={styles.line} d="m37.55,57.67c-.08,0-.16-.02-.23-.06-.25-.13-.34-.43-.22-.67l3.13-6.09c.12-.25.43-.34.67-.22.25.13.34.43.22.67l-3.13,6.09c-.09.17-.26.27-.44.27Z" />
                </g>
                <g>
                    <line class={styles.line} x1="47.41" y1="59.28" x2="47.41" y2="52.79" />
                    <path class={styles.line} d="m47.41,59.78c-.28,0-.5-.22-.5-.5v-6.49c0-.28.22-.5.5-.5s.5.22.5.5v6.49c0,.28-.22.5-.5.5Z" />
                </g>
                <g>
                    <line class={styles.line} x1="57.27" y1="57.17" x2="54.14" y2="51.08" />
                    <path class={styles.line} d="m57.27,57.67c-.18,0-.36-.1-.44-.27l-3.13-6.09c-.13-.25-.03-.55.22-.67.25-.13.55-.03.67.22l3.13,6.09c.13.25.03.55-.22.67-.07.04-.15.06-.23.06Z" />
                </g>
                <g>
                    <line class={styles.line} x1="59.06" y1="46.69" x2="65.17" y2="51.26" />
                    <path class={styles.line} d="m65.17,51.76c-.1,0-.21-.03-.3-.1l-6.11-4.57c-.22-.17-.27-.48-.1-.7.17-.22.48-.27.7-.1l6.11,4.57c.22.17.27.48.1.7-.1.13-.25.2-.4.2Z" />
                </g>
                <g>
                    <line class={styles.line} x1="69.56" y1="42.72" x2="61.54" y2="41" />
                    <path class={styles.line} d="m69.56,43.22s-.07,0-.11-.01l-8.01-1.71c-.27-.06-.44-.32-.38-.59.06-.27.32-.44.59-.38l8.01,1.71c.27.06.44.32.38.59-.05.24-.26.4-.49.4Z" />
                </g>
                <g>
                    <line class={styles.line} x1="69.56" y1="33.24" x2="61.54" y2="34.96" />
                    <path class={styles.line} d="m61.54,35.46c-.23,0-.44-.16-.49-.4-.06-.27.11-.54.38-.59l8.01-1.72c.27-.05.54.11.59.38.06.27-.11.54-.38.59l-8.01,1.72s-.07.01-.11.01Z" />
                </g>
                <g>
                    <line class={styles.line} x1="65.17" y1="24.7" x2="59.06" y2="29.26" />
                    <path class={styles.line} d="m59.06,29.76c-.15,0-.3-.07-.4-.2-.17-.22-.12-.54.1-.7l6.11-4.56c.22-.17.54-.12.7.1.17.22.12.54-.1.7l-6.11,4.56c-.09.07-.2.1-.3.1Z" />
                </g>
                <g>
                    <line class={styles.line} x1="57.27" y1="18.79" x2="54.14" y2="24.88" />
                    <path class={styles.line} d="m54.14,25.38c-.08,0-.16-.02-.23-.06-.25-.13-.34-.43-.22-.67l3.13-6.09c.12-.25.43-.34.67-.22.25.13.34.43.22.67l-3.13,6.09c-.09.17-.26.27-.44.27Z" />
                </g>
            </g>
        </svg>
    )
}

