import { Auth } from '@aws-amplify/auth'
import { API } from '@aws-amplify/api'
import { Strinky } from '@peachy/utility-kit-pure'


export type RestApi = typeof API

type HttpMethod = 'get' | 'post'

export class PeachyApiService {

    constructor(private api: RestApi = API, private apiName: string) {
    }

    async invokeWithAuthenticatedUser<ResponseType, RequestType = void>(path: string, params?: RequestType): Promise<ResponseType> {
        let request: Strinky<any> = {}
        const user = await Auth.currentAuthenticatedUser()

        request.headers = {
            Authorization: user.signInUserSession.idToken.jwtToken
        }

        return this.invoke(path, params, request)
    }

    async invoke<ResponseType, RequestType = void>(path: string, params?: RequestType, request: Strinky<any> = {}): Promise<ResponseType> {
        const method: HttpMethod = params ? 'post' :'get'

        if (params) {
            request.body = params
        }

        try {
            const response = await this.api[method](this.apiName, path, request)

            return response as ResponseType
        } catch (error) {
            console.log('API Error', error)
            throw error
        }
    }
}
