import {API} from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import {useServicePatch} from '@peachy/client-kit'
import {fetchServiceConfig} from '@peachy/core-domain-client'

//todo: put these names somewhere else as they are used in multiple places
const LookupApiName = 'LookupApi'
const PaymentsApiName = 'PaymentsApi'
const IamSmeApiName = 'IamSmeApi'

const region = 'eu-west-2'

export async function configureAws() {

    const servicePatchUrl = useServicePatch()

    //auth config
    const iamConfig = await fetchServiceConfig('IamSmeConfig', servicePatchUrl)

    Auth.configure({
        userPoolId: iamConfig.userPoolId,
        userPoolWebClientId: iamConfig.userPoolWebClientId,
        identityPoolId: iamConfig.identityPoolId,
        region,
    })

    //api config
    const lookupConfig = await fetchServiceConfig(LookupApiName, servicePatchUrl)
    const paymentsConfig = await fetchServiceConfig(PaymentsApiName, servicePatchUrl)
    const iamSmeApiConfig = await fetchServiceConfig(IamSmeApiName, servicePatchUrl)

    API.configure({
        endpoints: [
            {
                name: LookupApiName,
                ...lookupConfig
            },
            {
                name: PaymentsApiName,
                ...paymentsConfig
            },
            {
                name: IamSmeApiName,
                ...iamSmeApiConfig
            }
        ]
    })

}
