import {Life, Plan, Policy} from '@peachy/core-domain-pure'
import {Draft, newUUID} from '@peachy/utility-kit-pure'
import { addYears } from 'date-fns'

const startDate = new Date().getTime()

export const plan: Plan = {
    id: newUUID(),
    name: 'DalphPlan',
    benefits: [
        {
            type: 'CONSULTATIONS_DIAGNOSTICS',
            limit: 2_500_00
        },
        {
            type: 'MENTAL_HEALTH_IN',
        },
        {
            type: 'MENTAL_HEALTH_OUT',
        },
    ],
}

export const life: Draft<Life> = {
    id: newUUID(),
    planId: plan.id,
    type: 'PRIMARY',
    dateOfBirth: addYears(startDate, -50).getTime(),
    gender: 'MALE',
    address: {
        display: '',
        building: ['19 Farnaby Road'],
        settlement: ['Shortlands', 'Bromley'],
        country: 'United Kingdom',
        county: 'London',
        postcode: 'BR1 4BL',
        location: {
            lat: 0,
            lng: 0,
        },
        region: 'London'
    },
    postcode: 'BR1 4BL',
    benefits: [],
}


export const policy: Draft<Policy> = {
    id: newUUID(),
    lives: {
        [life.id]: life
    },
    startDate,
}



