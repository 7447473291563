import {ValidationErrors} from '../../global/errors/ValidationErrors'
import {createEffect, createSignal} from 'solid-js'
import {ValidationErrorMap} from '@peachy/core-domain-pure'
import { NextButton } from '../../components/PageRouter/NextButton'
import styles from './CaptureForm.module.css'
import { classList } from '@peachy/utility-kit-pure'

type AutoComplete = 'on' | 'off'

export interface CaptureFormProps<T> {
    children: any | any[]
    errorFields?: string[]
    onSubmit: (event: any) => void
    validateFields: () => ValidationErrorMap<T>
    autocomplete?: AutoComplete
    class?: string
}

export function CaptureForm<T>(props: CaptureFormProps<T>) {
    const [validationErrors, setValidationErrors] = createSignal([] as ValidationErrorMap<T>)
    const [touchedFields, setTouchedFields] = createSignal(new Set<string>())

    const hasErrors = () => props.errorFields.some(errorField => validationErrors()[errorField])

    const handleInputChange = (event: InputEvent) => {
        const targetElementName = (event.target as HTMLElement).getAttribute('name')

        if (targetElementName) {
            setTouchedFields(touchedFields().add(targetElementName))
        }
    }

    createEffect(() => {
        // @ts-ignore
        setValidationErrors(props.validateFields())
    })

    return <form class={classList(styles.captureForm, props.class)} onInput={handleInputChange} autocomplete={props.autocomplete ?? 'on'}>
        {props.children[0]}

        <ValidationErrors errors={validationErrors()} fields={props.errorFields} touchedFields={touchedFields()}/>

        {props.children.splice(1)}

        <NextButton {...props} disabled={hasErrors()} onClick={(event) => props.onSubmit(event)} theme="primary" />
    </form>
}