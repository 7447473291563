import { Plan, SmeEmployerPolicyDocData } from '@peachy/core-domain-pure'
import { ROOT_DOMAIN } from '../../../App'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { useDocGenService } from '../../../providers/AppServiceProvider'
import { createSamplePlanPolicy } from './DownloadSamplePlan'
 
const createEmployerDocTicket = (plans: Plan[], startDate: number) => ({
    templateName: 'SmeEmployerPolicyDoc',
    docData: {
        domain: ROOT_DOMAIN,
        isSample: true,
        subscriptionStartDate: startDate,
        planNumber: 'Your Reference Number',
        plans: plans,
        policies: plans.map(p => createSamplePlanPolicy(p, startDate)),
        company: {
            id: 'YOUR_COMPANY_ID',
            name: 'Your Company',
            address: {
                display: 'Your Company address'
            },
        },
        email: 'yourcompany@email.com'
    } as SmeEmployerPolicyDocData
})

export const DownloadCompanySamplePlan = () => {
    const store = useStore(), docGenService = useDocGenService()
    const allPlans = store.getPlans()

    const download = async () => {
        const ticket = createEmployerDocTicket(allPlans, store.getSubscriptionStartDate())
        const link = await docGenService.getPdfLink(ticket, `PeachyCompanyPlan-SampleLife.pdf`)
        
        window.open(link, `_CompanySamplePlanPdf`)
    }

    return (
        <a onClick={download}>sample company guide</a>
    )
}