import { PaymentsService } from '@peachy/payments-client'
import { createIamApi } from './iam/IamApi'
import { createIamService, IamService } from './iam/IamService'
import { API } from '@aws-amplify/api'
import { createSubscriptionService, SubscriptionService } from './subscription/SubscriptionService'
import { makeApiClient } from '@peachy/core-domain-client'
import { SmeSubscriptionApiDef } from '@peachy/subscription-pure'
import { useServicePatch } from '@peachy/client-kit'
import Auth from '@aws-amplify/auth'
import { SmeUserService } from '@peachy/iam-sme-client'
import { createDocGenService, DocGenService } from './DocGenService'
import { DocGenApiDefinition } from '@peachy/doc-gen-pure'
import { Signer } from '@aws-amplify/core'

export type AppServices = {
    iamService: IamService
    paymentsService: PaymentsService
    subscriptionService: SubscriptionService
    smeUserService : SmeUserService
    docGenService: DocGenService
}

export async function createAppServices(): Promise<AppServices> {

    const paymentsService = await PaymentsService.create(API)
    const servicePatch = useServicePatch()
    const smeUserService = new SmeUserService(API)

    return {
        iamService: createIamService(createIamApi(), smeUserService),
        paymentsService: paymentsService,
        subscriptionService: createSubscriptionService(await makeApiClient(SmeSubscriptionApiDef, servicePatch, Signer, Auth)),
        smeUserService: smeUserService,
        docGenService: createDocGenService(await makeApiClient(DocGenApiDefinition, servicePatch, Signer)) 
    }
}
