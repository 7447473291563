import { SubscriptionRequest } from '@peachy/core-domain-pure'
import { subscriptionRequestSessionStore } from '../../providers/AccountSubscription/session/stores'
import { Draft } from '@peachy/utility-kit-pure'
import { useSubscriptionService } from '../../providers/AppServiceProvider'

export async function loadSubscriptionRequest (): Promise<Draft<SubscriptionRequest>> {
    const sessionStore = subscriptionRequestSessionStore()

    sessionStore.delete() // delete any in-flight subscription requests

    const storedSubscriptionRequest = sessionStore.get()

    if (storedSubscriptionRequest) {
        return storedSubscriptionRequest
    }

    const subscriptionRequest = await useSubscriptionService().getDraftSmeSubscription({})

    if (subscriptionRequest) {
        sessionStore.save(subscriptionRequest)
    }

    return subscriptionRequest as Draft<SubscriptionRequest>
}
