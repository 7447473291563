import { Life, Policy } from "@peachy/core-domain-pure"
import { Draft, isAgeBefore } from "@peachy/utility-kit-pure"
import { Component, For, createMemo } from "solid-js"
import Child from "../../../../global/icons/Child"
import { useStore } from "../../../../providers/AccountSubscription/AccountSubscriptionProvider"
import tableStyles from '../ManageMembersTable.module.css'
import { BirthDateCell } from "../cell/BirthDateCell"
import { EmailCell } from "../cell/EmailCell"
import { FirstNameCell } from "../cell/FirstNameCell"
import { LastNameCell } from "../cell/LastNameCell"
import { RemoveDependant } from "../cell/RemoveDependant"
import { getDependants } from "../../../../providers/AccountSubscription/stores/AccountSubscriptionStore"
import { DEPENDANT_MIN_AGE_EMAIL_REQUIRED } from "../../../../service/validation/ValidationService"

export const DependantRows: Component<{ policy: Draft<Policy>, policyIndex: number }> = (props) => {
    const store = useStore()

    const dependantsCellProps = createMemo(() => getDependants(props.policy).map(dependant => ({
        life: dependant,
        policyId: props.policy.id,
        policyIndex: props.policyIndex,
        readonly: !store.canEditLife(dependant.id)
    })))

    const isEmailReadOnly = (dependant: Draft<Life>): boolean => dependant.dateOfBirth && dependant.dateOfBirth <= props.policy.startDate
        ? isAgeBefore(dependant.dateOfBirth, props.policy.startDate, DEPENDANT_MIN_AGE_EMAIL_REQUIRED) : true

    return (
        <For each={dependantsCellProps()}>{
            (dependantCellProps) => {
                const readonlyEmail = createMemo(() => isEmailReadOnly(dependantCellProps.life))

                return (
                    <tr>
                        <td><i class="fa-solid fa-arrow-turn-down-right" /></td>
                        <FirstNameCell {...dependantCellProps} placeholder="Child"/>
                        <LastNameCell {...dependantCellProps} />
                        <BirthDateCell {...dependantCellProps} startDate={props.policy.startDate}
                            onBlur={() => readonlyEmail() && store.updateLife(props.policyIndex, dependantCellProps.life.id, { email: '' })} />
                        <td />{/* address field not needed  */}
                        <EmailCell {...dependantCellProps} startDate={props.policy.startDate}
                            readonly={dependantCellProps.readonly || readonlyEmail()} 
                            disable={readonlyEmail() && store.getLockLives().some(l => l.id === dependantCellProps.life.id)}
                            />
                        <td />{/* plan field not needed  */}
                        <td class={tableStyles.dependants}>
                            <span />
                            <span><Child /></span>
                        </td>
                        <RemoveDependant {...dependantCellProps} />
                    </tr>
                )
            }
        }
        </For>
    )
}