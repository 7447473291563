import styles from './BenefitIcons.module.css'

export default function () {

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.02 91.04">
            <path class={styles.line} d="M46.4,55H34.81a5.42,5.42,0,0,1-5.41-5.41v-26a5.42,5.42,0,0,1,5.41-5.41H46.4a5.42,5.42,0,0,1,5.42,5.41v26A5.42,5.42,0,0,1,46.4,55ZM34.81,19.19A4.42,4.42,0,0,0,30.4,23.6v26A4.42,4.42,0,0,0,34.81,54H46.4a4.42,4.42,0,0,0,4.42-4.41v-26a4.42,4.42,0,0,0-4.42-4.41Z"/>
            <path class={styles.line} d="M40.61,67.78l-.3-.23c-11-8.36-16.67-16.38-16.8-23.85a13.49,13.49,0,0,1,4.41-10.34l.63.78a12.65,12.65,0,0,0-4,9.57c.13,7.07,5.55,14.74,16.1,22.81,10.55-8.07,16-15.74,16.09-22.81a12.7,12.7,0,0,0-4-9.57l.63-.78A13.5,13.5,0,0,1,57.7,43.7c-.12,7.47-5.77,15.49-16.79,23.85Z"/>
            <path class={styles.line} d="M28.77,34.81a1.6,1.6,0,1,1,1.6-1.6A1.61,1.61,0,0,1,28.77,34.81Zm0-2.2a.6.6,0,1,0,.6.6A.6.6,0,0,0,28.77,32.61Z"/>
            <path class={styles.line} d="M52.42,34.81a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,52.42,34.81Zm0-2.2a.6.6,0,1,0,.6.6A.6.6,0,0,0,52.42,32.61Z"/>
            <path class={styles.line} d="M52.83,79.51A12.69,12.69,0,0,1,40.11,66.89h1a11.73,11.73,0,0,0,23.45,0c0-4.51,1.74-6.2,3.28-7.68a7,7,0,0,0,2.7-6c0-2.89-1.16-4.27-2.49-5.88A10.8,10.8,0,0,1,65,39.55h1a9.8,9.8,0,0,0,2.84,7.18c1.34,1.61,2.72,3.27,2.72,6.52a8,8,0,0,1-3,6.67c-1.53,1.48-3,2.88-3,7A12.69,12.69,0,0,1,52.83,79.51Z"/>
            <path class={styles.line} d="M40.44,63a17.26,17.26,0,0,1-7.35-1.7l.43-.9a16,16,0,0,0,13.95,0l.42.91A17.93,17.93,0,0,1,40.44,63Z"/>
            <path class={styles.line} d="M43.15,20.76H38.36a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h4.79a.5.5,0,0,1,.5.5A.5.5,0,0,1,43.15,20.76Z"/>
            <path class={styles.line} d="M65.48,40.05a4.64,4.64,0,1,1,4.64-4.63A4.64,4.64,0,0,1,65.48,40.05Zm0-8.27a3.64,3.64,0,1,0,3.64,3.64A3.65,3.65,0,0,0,65.48,31.78Z"/>
        </svg>
    )
} 
