import { Subscription, toClass } from '@peachy/core-domain-pure'
import { classList } from '@peachy/utility-kit-pure'
import { Component, createMemo } from 'solid-js'
import { getAccount, getSubscription } from '../../../../components/Home/HomeProvider'
import styles from '../InfoPanels.module.css'
import { BillingCompanyDetailsPanel } from './BillingCompanyDetailsPanel'
import { BillingPaymentDetailsPanel } from './BillingPaymentDetailsPanel'

export const BillingInfoPanels: Component = () => {
    const subscription = createMemo(() => toClass(getSubscription(), Subscription))
    
    return (
        <div class={classList(styles.InfoPanel, styles.BillingInfoPanel)}>
            <BillingPaymentDetailsPanel subscription={subscription()}/>
            <BillingCompanyDetailsPanel company={getAccount()?.company} billingAddress={subscription().paymentDetails?.billingAddress} />
        </div>
    )
}