export default function () {

    const common = {
        'stroke': '#1d1d1b',
        'stroke-miterlimit': 10,
        'stroke-width': '2px'
    }

    const class1 = {
        ...common,
        'fill': '#fff'
    }

    const class2 = {
        ...common, 
        'stroke-linecap': 'round',
        'fill': 'none'
    }

    const class3 = {
        ...common,
        'fill': 'none'
    }

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.66 75.96">
            <path style={class3} d="m76.23,19.54c-.64-2.97-16.47-5.5-24.34,7.02l-.77,32.16c-.03,1.28,1.53,1.95,2.42,1.04,10.57-10.9,23-4.75,26.35-2.77.38.23.86-.06.85-.5-.26-7.75-3.34-31.55-4.51-36.94Z"/>
            <path style={class3} d="m29.08,14.31c.64-2.94,13.68-5.2,21.58,6.91.15.23.23.51.23.79l.12,36.73c0,1.29-1.59,1.92-2.46.96-10.18-11.32-20.41-8.73-23.24-7.7-.37.14-.77-.15-.75-.54.22-7.53,3.34-31.7,4.51-37.14Z"/>
            <path style={class1} d="m72.7,14.31c-.61-2.95-13.68-5.2-21.58,6.9-.15.23-.23.51-.23.79l.12,36.76c0,1.29,1.6,1.91,2.46.95,10.14-11.33,20.18-8.77,22.98-7.73.37.14.77-.14.76-.54-.22-7.54-4.4-36.57-4.51-37.14Z"/>
            <path style={class2} d="m32.1,17.34c5.69-2.93,10.66,1.3,15.29,6.32"/>
            <path style={class2} d="m31.51,23.09c5.69-2.93,10.66,1.3,15.29,6.32"/>
            <path style={class2} d="m31.51,29.9c5.69-2.93,10.66,1.3,15.29,6.32"/>
            <path style={class2} d="m69.65,18.08c-5.69-2.93-10.66,1.3-15.29,6.32"/>
            <path style={class2} d="m70.24,23.84c-5.69-2.93-10.66,1.3-15.29,6.32"/>
            <path style={class2} d="m70.24,30.64c-5.69-2.93-10.66,1.3-15.29,6.32"/>
        </svg>
    )
}