import { classList } from '@peachy/utility-kit-pure'
import { Component } from 'solid-js'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { SubHeader } from './SubHeader'
import { quoteNav } from '../Quote'
import styles from './BusinessDetails.module.css'
import summaryStyles from './Summary.module.css'

const BusinessDetail: Component<{ label: string, value: string }> = (props) => {
    return <div class={styles.Details}>
        <p>{props.label}: </p>
        <p>{props.value}</p>
    </div>
}
export const BusinessDetails = () => {
    const store = useStore()

    return (
        <div class={classList(summaryStyles.DetailsContainer, styles.Contents)}>
            <SubHeader heading={'Business Details'} onEditClick={quoteNav.toBusinessDetails}/>
            <BusinessDetail label={'Name'} value={store.getCompany()?.name}></BusinessDetail>
            <BusinessDetail label={'Address'} value={store.getCompany()?.address?.display}></BusinessDetail>
            <BusinessDetail label={'Company Number'} value={store.getCompany()?.id}></BusinessDetail>
        </div>
    )
}

