import { Component } from 'solid-js'
import { LifeRowCellProps, validateFieldCss } from '../PolicyRow'
import { useStore } from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import tableStyles from '../ManageMembersTable.module.css'
import { createToggleSignal, TextBox } from '@peachy/client-kit'

export const LastNameCell: Component<LifeRowCellProps> = (props) => {
    const store = useStore()
    const life = props.life

    const [isFocus, setIsFocus] = createToggleSignal(false)
    const textBoxCss = () => isFocus() ? '' : validateFieldCss(life, 'lastname')

    return <td class={tableStyles.name}>
        <TextBox value={life.lastname ?? ''}
                 onChange={lastname => store.updateLife(props.policyIndex, life.id, {lastname: lastname.trim()})}
                 class={textBoxCss()}
                 onBlur={setIsFocus}
                 onFocus={setIsFocus}
                 readonly={props.readonly}
        />
    </td>
}