import { classList } from '@peachy/utility-kit-pure'
import { ParentComponent, mergeProps } from 'solid-js'
import styles from './Card.module.css'

export const Card: ParentComponent<{ class?: string }> = (propsIn) => {
    const props = mergeProps({ class: styles.Default }, propsIn)

    return (
        <div class={classList(styles.Card, props.class)}>
            {props.children}
        </div>
    )
}
