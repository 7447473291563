import { classList } from '@peachy/utility-kit-pure'
import { Match, Switch, mergeProps } from 'solid-js'
import { Price } from './Price'
import styles from './FormattedPrice.module.css'

type Frequency = 'monthly' | 'yearly'

interface FormattedPriceProps {
    price: number
    frequency?: Frequency
    class?: string
}

export function FormattedPrice(propsIn: FormattedPriceProps) {

    const props = mergeProps({frequency: 'monthly'}, propsIn)

    return (
        <span class={classList(styles.FormattedPrice, props.class)}>
            <Price value={props.price}/>
            <span>
                <Switch>
                    <Match when={props.frequency === 'monthly'}> /month</Match>
                    <Match when={props.frequency === 'yearly'}> /year</Match>
                </Switch>
            </span>
        </span>
    )
}
