import {Show} from 'solid-js'
import {Button, ButtonProps} from '../../global/forms/Button'
import { hasNextPage, nextPage } from './PageRouter'

export interface NextButtonProps extends ButtonProps {
    onClick?: (event: any) => void
}

export function NextButton (props: ButtonProps) {

    const onClick = async (event: any) => {
        props.onClick && await props.onClick?.(event)
        nextPage()
    }

    return <Show when={hasNextPage()} fallback={<span/>}>
        <Button {...props} theme="primary" onClick={onClick}>Next</Button>
    </Show>
}