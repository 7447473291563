import { AccountSubscriptionDto, SubscriptionRequest } from '@peachy/core-domain-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { ParentComponent, createContext, useContext } from 'solid-js'
import { createStore } from 'solid-js/store'
import { IStore } from '../../../global/storage/IStore'
import { editSubscriptionSessionStore } from '../../../providers/AccountSubscription/session/stores'
import { SolidStore } from '../../../global/storage/SolidStore'
import { getAccountSubscription } from '../../../components/Home/HomeProvider'
import _ from 'lodash-es'

type ContextState = {
    isEditing: boolean

    stores: {
        sessionStore: IStore<Draft<SubscriptionRequest>>
        solidStore: IStore<Draft<SubscriptionRequest>>
    }
}

type ContextValue = [
    state: ContextState,
    actions: {
        editPlan(isEditing: boolean): void
        isEditingPlan(): boolean
    }
]

const YourPlanContext = createContext<ContextValue>()

export const YourPlanProvider: ParentComponent = (props) => {

    const sessionStore = editSubscriptionSessionStore()
    const subscriptionRequest = map(getAccountSubscription())

    const [state, setState] = createStore({
        isEditing: sessionStore.exists(),
        stores: {
            sessionStore: sessionStore,
            solidStore: new SolidStore<Draft<SubscriptionRequest>>(subscriptionRequest)
        }
    })

    const isEditingPlan = () => state.isEditing

    const editPlan = (isEditing: boolean) => {
        if (isEditing && !sessionStore.exists()) {
            sessionStore.save(subscriptionRequest)
        }

        setState('isEditing', isEditing)
    }

    return (
        <YourPlanContext.Provider value={ [state, { editPlan, isEditingPlan }] as ContextValue }>
            {props.children}
        </YourPlanContext.Provider>
    )
}

export const getYourPlanContext = () => useContext(YourPlanContext)

export function isEditingPlan (): boolean {
    const [_, { isEditingPlan }] = getYourPlanContext()
    return isEditingPlan()
}

export function getSolidStore() {
    const [state] = getYourPlanContext()
    return state.stores.solidStore
}

export function getSessionStore() {
    const [state] = getYourPlanContext()
    return state.stores.sessionStore
}

const map = (dto: AccountSubscriptionDto): Draft<SubscriptionRequest> => {
    const dtoClone = _.cloneDeep(dto)

    const { account, subscription } = dtoClone
    const { policies, plans, ...restSubscriptionProps } = subscription

    return {
        account, 
        subscription: restSubscriptionProps,
        plans,
        policies
    } as Draft<SubscriptionRequest>
}