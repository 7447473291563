import { Component } from 'solid-js'
import { PlanBuilder } from '../../../components/PlanBuilder/PlanBuilder'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'

export const QuotePlanBuilder: Component = () => {
    const store = useStore()

    store.setPlanEditable(true)
    store.setViewMode('QUOTE')

    return (<PlanBuilder/>)
}