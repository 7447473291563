import styles from './BenefitIcons.module.css'

export default function () {
    const class2 = { 'stroke-width': '0.57px' }

    return (
        <svg id="Layer_1" data-name="Layer 1"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.9 89.96">
            <path class={styles.line} d="M59.28,56.1l-.35-.45a2.77,2.77,0,0,0-.77-.68c.37,3.86.83,7.69,1.12,10a1.23,1.23,0,0,1-.27.95,1.13,1.13,0,0,1-.88.42H33.83a1.12,1.12,0,0,1-.87-.42,1.2,1.2,0,0,1-.27-.95c.27-2.18.7-5.83,1.07-9.54a2.52,2.52,0,0,0-.4.4l-.75,1c-.32,3.16-.68,6.13-.91,8a2.34,2.34,0,0,0,.51,1.79,2.11,2.11,0,0,0,1.62.77h24.3a2.13,2.13,0,0,0,1.63-.78,2.32,2.32,0,0,0,.51-1.78C60,62.77,59.62,59.51,59.28,56.1Z"/>
            <path class={styles.line} d="M61.93,22.4a2.12,2.12,0,0,0-1.7-.86H31.74a2.1,2.1,0,0,0-1.7.86,2.32,2.32,0,0,0-.4,1.93c.71,3.14,2.08,9.33,3,14.32a4.09,4.09,0,0,1,.94-.41c-.88-5-2.21-11.06-2.92-14.16a1.26,1.26,0,0,1,.22-1,1.14,1.14,0,0,1,.91-.46H60.23a1.14,1.14,0,0,1,.91.46,1.26,1.26,0,0,1,.22,1c-.7,3.07-2,9-2.91,14a2.79,2.79,0,0,1,1,.32c.88-5,2.21-10.95,2.92-14A2.36,2.36,0,0,0,61.93,22.4Z"/>
            <path class={styles.line} style={class2} d="M42.43,30a1,1,0,0,1-.71-.35,1,1,0,0,1-.21-.89c.64-2.14.55-2.56,0-4.2a.93.93,0,0,1,.24-.95.85.85,0,0,1,.82-.28,14.73,14.73,0,0,0,7.15,0,.93.93,0,0,1,.84.33.94.94,0,0,1,.2.91,5.35,5.35,0,0,0,0,4.19.91.91,0,0,1-.2.87.89.89,0,0,1-.81.36h0a30.54,30.54,0,0,0-7.22,0Zm0-6a.23.23,0,0,0-.14.11.23.23,0,0,0,0,.2,5.88,5.88,0,0,1,0,4.63.22.22,0,0,0,.07.21c.06.08.14.11.16.1a31.62,31.62,0,0,1,7.41,0h0a.26.26,0,0,0,.18-.11.22.22,0,0,0,.06-.2,6.15,6.15,0,0,1,0-4.64.23.23,0,0,0-.06-.19.39.39,0,0,0-.16-.11A15.68,15.68,0,0,1,42.39,24Z"/>
            <path class={styles.line} style={class2} d="M42.41,65.35a1,1,0,0,1-.92-1.24c.64-2.14.55-2.56,0-4.2a1,1,0,0,1,.24-.95.85.85,0,0,1,.82-.28,14.73,14.73,0,0,0,7.15,0,.93.93,0,0,1,.84.33.94.94,0,0,1,.2.91,5.35,5.35,0,0,0,0,4.19,1,1,0,0,1-1,1.23h0a30,30,0,0,0-7.22,0Zm0-6a.23.23,0,0,0-.14.11.22.22,0,0,0,0,.2,5.88,5.88,0,0,1,0,4.63.22.22,0,0,0,.07.21.21.21,0,0,0,.17.1,31.53,31.53,0,0,1,7.4,0,.26.26,0,0,0,.18-.11.22.22,0,0,0,.07-.2,6.1,6.1,0,0,1,0-4.64.26.26,0,0,0-.06-.2.53.53,0,0,0-.16-.1A15.68,15.68,0,0,1,42.37,59.38Z"/>
            <path class={styles.line} style={class2} d="M42.29,58.28A1,1,0,0,1,41.37,57c.64-2.14.55-2.57,0-4.2a1,1,0,0,1,.24-1,.86.86,0,0,1,.82-.27,14.85,14.85,0,0,0,7.15,0,.91.91,0,0,1,.84.33.94.94,0,0,1,.2.91,5.35,5.35,0,0,0,0,4.19,1,1,0,0,1-1,1.23h0a30,30,0,0,0-7.22,0Zm0-6a.29.29,0,0,0-.14.11.23.23,0,0,0,0,.2,5.91,5.91,0,0,1,0,4.64.23.23,0,0,0,.07.21.28.28,0,0,0,.17.1,30.48,30.48,0,0,1,7.4,0,.33.33,0,0,0,.18-.11.22.22,0,0,0,.07-.2,6.1,6.1,0,0,1,0-4.64.25.25,0,0,0-.06-.2.26.26,0,0,0-.16-.1A15.69,15.69,0,0,1,42.25,52.3Z"/>
            <path class={styles.line} style={class2} d="M42.29,37.06a1,1,0,0,1-.92-1.24c.64-2.14.55-2.56,0-4.19a1,1,0,0,1,.24-1,.82.82,0,0,1,.82-.27,14.84,14.84,0,0,0,7.15,0,1,1,0,0,1,1,1.24,5.35,5.35,0,0,0,0,4.19.91.91,0,0,1-.2.87.89.89,0,0,1-.81.36h0a31.08,31.08,0,0,0-7.22,0Zm0-6a.29.29,0,0,0-.14.11.23.23,0,0,0,0,.2,5.88,5.88,0,0,1,0,4.63.22.22,0,0,0,.07.21.21.21,0,0,0,.17.1,31.53,31.53,0,0,1,7.4,0A.29.29,0,0,0,50,36a6.1,6.1,0,0,1,0-4.64.23.23,0,0,0-.06-.19.27.27,0,0,0-.16-.11A15.55,15.55,0,0,1,42.25,31.09Z"/>
            <path class={styles.line} style={class2} d="M42.29,44.13a1,1,0,0,1-.71-.35.93.93,0,0,1-.21-.88c.64-2.15.55-2.57,0-4.2a1,1,0,0,1,.24-1,.82.82,0,0,1,.82-.27,14.72,14.72,0,0,0,7.15,0,1,1,0,0,1,1,1.24,5.35,5.35,0,0,0,0,4.19,1,1,0,0,1-1,1.23h0a31.08,31.08,0,0,0-7.22,0Zm0-6a.29.29,0,0,0-.14.11.23.23,0,0,0,0,.2,5.88,5.88,0,0,1,0,4.63.24.24,0,0,0,.07.22.26.26,0,0,0,.16.09,31.62,31.62,0,0,1,7.41,0h0a.22.22,0,0,0,.18-.1A.25.25,0,0,0,50,43.1a6.1,6.1,0,0,1,0-4.64.23.23,0,0,0-.06-.19.26.26,0,0,0-.16-.1A15.69,15.69,0,0,1,42.25,38.16Z"/>
            <path class={styles.line} style={class2} d="M42.16,51.2A1,1,0,0,1,41.24,50c.64-2.15.55-2.57,0-4.2a1,1,0,0,1,.25-1,.82.82,0,0,1,.81-.27,14.75,14.75,0,0,0,7.16,0,.9.9,0,0,1,.83.34.91.91,0,0,1,.2.91,5.35,5.35,0,0,0,0,4.19,1,1,0,0,1-.2.87.94.94,0,0,1-.82.36h0a30,30,0,0,0-7.22,0Zm0-6a.25.25,0,0,0-.14.11.24.24,0,0,0-.06.2,5.88,5.88,0,0,1,0,4.63.27.27,0,0,0,.06.22c.07.07.14.11.17.1a30.57,30.57,0,0,1,7.41,0h0a.41.41,0,0,0,.18-.11.25.25,0,0,0,.06-.21,6.08,6.08,0,0,1,0-4.63.19.19,0,0,0-.06-.2.29.29,0,0,0-.16-.1A15.65,15.65,0,0,1,42.12,45.23Z"/>
            <path class={styles.line} d="M41.74,45.92c.16,1.28.32,2.48-.07,3.17l-.16.28c-1.16,2.06-2.18,3.79-2.94,4.2a15.41,15.41,0,0,1-2.23.89,9.38,9.38,0,0,0-2.28,1c-.48.44-3.49,4.5-5.4,7.12a.42.42,0,0,1-.36.19.44.44,0,0,1-.27-.09.45.45,0,0,1-.1-.63c.49-.67,4.81-6.62,5.52-7.26A8.64,8.64,0,0,1,36,53.61a15.84,15.84,0,0,0,2.1-.83c.59-.32,1.88-2.62,2.58-3.85l.16-.28a5.94,5.94,0,0,0,0-2.5c-.21.31-.47.73-.75,1.17L38.42,50a.46.46,0,0,1-.38.2.45.45,0,0,1-.38-.7c.12-.17,1-1.54,1.69-2.64l1.07-1.69.45-.68c.6-.88,1.21-1.78,1.09-2.33a.78.78,0,0,0-.42-.48c-.38-.23-.91.27-1.6,1.17,0,0,0,.09-.07.13a.34.34,0,0,1-.12.12c-.33.45-.7,1-1.09,1.55l-1.06,1.5a.45.45,0,1,1-.73-.53c.37-.52.72-1,1-1.49.46-.66.88-1.26,1.26-1.76a4.08,4.08,0,0,0,.2-1.54.83.83,0,0,0-.49-.72.46.46,0,0,0-.53.18c-.48.54-1.16,1.5-1.82,2.42-.44.61-.89,1.24-1.29,1.78a.45.45,0,0,1-.36.18.44.44,0,0,1-.28-.1.45.45,0,0,1-.08-.63c.39-.52.84-1.15,1.27-1.75.28-.39.54-.76.8-1.11a.06.06,0,0,1,0,0,2.76,2.76,0,0,0,.15-1.4.55.55,0,0,0-.41-.47.77.77,0,0,0-.8.24l-.44.55c-.68.86-1.53,1.93-2.37,3a.44.44,0,0,1-.36.18A.45.45,0,0,1,32,42.4c.6-.78,1.21-1.55,1.75-2.24A2.72,2.72,0,0,0,34.05,39c0-.17-.06-.19-.1-.21a1.68,1.68,0,0,0-1.45.54,8.12,8.12,0,0,0-1,1.12l0,0h0c-.11.13-.26.31-.46.52a22.66,22.66,0,0,0-2.59,3.36,7.75,7.75,0,0,0-.64,2.7,8.41,8.41,0,0,1-.93,3.49c-.67,1-5.29,6.43-5.49,6.66a.46.46,0,0,1-.34.15.47.47,0,0,1-.3-.1.46.46,0,0,1,0-.64c0-.06,4.79-5.6,5.43-6.57a7.75,7.75,0,0,0,.78-3.1,7.94,7.94,0,0,1,.77-3.06,22.6,22.6,0,0,1,2.69-3.49l.43-.49h0l0,0a8.48,8.48,0,0,1,1.08-1.2,2.47,2.47,0,0,1,2.44-.72,1,1,0,0,1,.62.8,1.68,1.68,0,0,1,1.66-.44,1.45,1.45,0,0,1,1.06,1.23,1,1,0,0,0,0,.17h0a1.35,1.35,0,0,1,1.53-.42,1.72,1.72,0,0,1,1.08,1.56c0,.11,0,.22,0,.32A1.38,1.38,0,0,1,42,40.87a1.67,1.67,0,0,1,.84,1.06c.21.93-.48,2-1.21,3C41.66,45.26,41.7,45.58,41.74,45.92Z"/>
            <line class={styles.line} x1="21.02" y1="56.81" x2="28.33" y2="62.27"/>
            <rect class={styles.line} x="24.17" y="54.98" width="1" height="9.12" transform="translate(-37.79 43.68) rotate(-53.24)"/>
            <path class={styles.line} d="M50.4,45.92a5.94,5.94,0,0,0,.08,3.17l.16.28c1.16,2.06,2.18,3.79,2.93,4.2a15.12,15.12,0,0,0,2.24.89,9.56,9.56,0,0,1,2.28,1c.48.44,3.49,4.5,5.39,7.12a.44.44,0,0,0,.37.19.46.46,0,0,0,.37-.72c-.5-.67-4.82-6.62-5.52-7.26a8.84,8.84,0,0,0-2.6-1.16A15.64,15.64,0,0,1,54,52.78c-.59-.32-1.89-2.62-2.58-3.85l-.16-.28a5.78,5.78,0,0,1,0-2.5c.2.31.46.73.75,1.17.73,1.16,1.57,2.47,1.69,2.65a.44.44,0,0,0,.37.2.48.48,0,0,0,.26-.07.46.46,0,0,0,.12-.63L52.8,46.83l-1.08-1.69c-.14-.23-.3-.45-.45-.68-.59-.88-1.21-1.78-1.08-2.33,0-.07.06-.27.42-.48s.91.27,1.6,1.17a.54.54,0,0,0,.07.13.43.43,0,0,0,.11.12c.33.45.7,1,1.1,1.55s.67,1,1,1.5a.46.46,0,1,0,.74-.53c-.38-.52-.73-1-1.05-1.49-.46-.66-.87-1.26-1.25-1.76a3.88,3.88,0,0,1-.21-1.54.84.84,0,0,1,.5-.72.44.44,0,0,1,.52.18c.48.54,1.16,1.5,1.82,2.42.44.61.89,1.24,1.3,1.78a.44.44,0,0,0,.36.18.41.41,0,0,0,.27-.1.45.45,0,0,0,.09-.63c-.4-.52-.85-1.15-1.28-1.75-.27-.39-.54-.76-.79-1.11a.06.06,0,0,1,0,0,2.55,2.55,0,0,1-.15-1.4.54.54,0,0,1,.41-.47.76.76,0,0,1,.79.24l.44.55c.69.86,1.54,1.93,2.37,3a.45.45,0,0,0,.36.18.47.47,0,0,0,.28-.09.46.46,0,0,0,.08-.64c-.6-.78-1.2-1.55-1.75-2.24A2.84,2.84,0,0,1,58.1,39c0-.17.06-.19.1-.21a1.66,1.66,0,0,1,1.44.54,7.54,7.54,0,0,1,1,1.12l0,0h0l.46.52a22,22,0,0,1,2.59,3.36,7.33,7.33,0,0,1,.64,2.7,8.56,8.56,0,0,0,.93,3.49c.67,1,5.3,6.43,5.5,6.66a.43.43,0,0,0,.34.15.44.44,0,0,0,.34-.74c0-.06-4.78-5.6-5.42-6.57a7.91,7.91,0,0,1-.79-3.1,7.94,7.94,0,0,0-.77-3.06,23.34,23.34,0,0,0-2.68-3.49l-.43-.49h0l0,0a7.91,7.91,0,0,0-1.08-1.2,2.45,2.45,0,0,0-2.43-.72,1,1,0,0,0-.63.8,1.66,1.66,0,0,0-1.65-.44A1.43,1.43,0,0,0,54.5,39.5a1,1,0,0,0,0,.17h0A1.33,1.33,0,0,0,53,39.24a1.71,1.71,0,0,0-1.08,1.56v.32a1.36,1.36,0,0,0-1.72-.25,1.63,1.63,0,0,0-.84,1.06c-.21.93.47,2,1.21,3Q50.48,45.41,50.4,45.92Z"/>
            <line class={styles.line} x1="71.12" y1="56.81" x2="63.82" y2="62.27"/>
            <rect class={styles.line} x="62.91" y="59.04" width="9.12" height="1" transform="translate(-22.21 52.18) rotate(-36.74)"/>
        </svg>
    )
}


