import {Draft} from './mixin/mixin-kit'
import {QuoteRequest, QuoteResponse} from '@peachy/quote-pure'
import {createStore, SetStoreFunction, Store} from 'solid-js/store'

export class QuoteStore {

    private quoteRequestStore: Store<Draft<QuoteRequest>>
    private updateQuoteRequestStore: SetStoreFunction<Draft<QuoteRequest>>

    private quoteResponseStore: Store<Draft<QuoteResponse>>
    private updateQuoteResponseStore: SetStoreFunction<Draft<QuoteResponse>>

    constructor() {
        [this.quoteRequestStore, this.updateQuoteRequestStore] = createStore<Draft<QuoteRequest>>({})
        ;[this.quoteResponseStore, this.updateQuoteResponseStore] = createStore<Draft<QuoteResponse>>({})
    }

    updateQuoteRequest(quoteRequest: Draft<QuoteRequest>) {
        this.updateQuoteRequestStore(quoteRequest)
    }

    updateQuoteResponse(quoteResponse: QuoteResponse) {
        this.updateQuoteResponseStore(quoteResponse)
    }

    clearQuoteResponse() {
        this.updateQuoteResponse(null)
    }

    quoteRequest() {
        return this.quoteRequestStore
    }
    quoteResponse() {
        return this.quoteResponseStore
    }
}