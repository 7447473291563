import { SubscriptionRequest } from '@peachy/core-domain-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { SubscriptionService } from './SubscriptionService'
import { debounce } from '@solid-primitives/scheduled'
import { IStore } from '../../global/storage/IStore'

export class SubscriptionRequestRepository {
    private store: IStore<Draft<SubscriptionRequest>>
    private subscriptionService: SubscriptionService            

    constructor (store: IStore<Draft<SubscriptionRequest>>, subscriptionService?: SubscriptionService) {
        this.store = store
        this.subscriptionService = subscriptionService
    }

    save (subscriptionRequest: Draft<SubscriptionRequest>) {
        this.store.save(subscriptionRequest)

        if (this.subscriptionService && subscriptionRequest?.account?.user?.cognitoUserId) {
            this.throttledSave(subscriptionRequest as SubscriptionRequest)
        }
    }

    get (): Draft<SubscriptionRequest> {
        return this.store.get()
    }

    delete () {
        this.store.delete()
    }
    
    throttledSave = debounce((subRequest: SubscriptionRequest) => this.subscriptionService.saveDraftSmeSubscription(subRequest), 3000)
}