import {createSignal, Match, Signal, Switch} from 'solid-js'
import {SignIn} from './steps/SignIn'
import {CompleteNewPassword} from './steps/CompleteNewPassword'
import {ResetPassword} from './steps/ResetPassword'
import {NOOP} from '@peachy/utility-kit-pure'
import {Modal, Spinner} from '@peachy/client-kit'
import { Card } from '../Card/Card'
import { useIamService } from '../../providers/AppServiceProvider'
import styles from './IamModal.module.css'

export type IamProps = {
    email: Signal<string>
}

export function IamModal() {
    const iamService = useIamService()

    const emailSignal = createSignal('')

    return (<>
        <Modal isOpen={!iamService.isInState('SignedIn')} onDismiss={NOOP}>
            <Card class={styles.IamModal}>
                <Switch>
                    <Match when={iamService.isInState('Anon')}>
                        <SignIn email={emailSignal}/>
                    </Match>
                    <Match when={iamService.isInState('NewPasswordRequired')}>
                        <CompleteNewPassword/>
                    </Match>
                    <Match when={iamService.isInState('PasswordResetRequested')}>
                        <ResetPassword email={emailSignal}/>
                    </Match>
                </Switch>
            </Card>
        </Modal>
        <Spinner isShown={iamService.isBusy()} />
    </>
    )
}