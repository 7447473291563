import tableStyles from '../ManageMembersTable.module.css'
import { createToggleSignal, DateBox, DATEBOX_TEXT_LENGTH } from '@peachy/client-kit'
import { useStore } from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { Component, createSignal } from 'solid-js'
import { LifeRowCellProps } from '../PolicyRow'
import { classList, formatDmyDate, parseDmyDate } from '@peachy/utility-kit-pure'
import { isValidDob, LIFE_WARNING_ERROR_MESSAGES } from '../../../../service/validation/ValidationService'
import policyRowStyles from '../PolicyRow.module.css'
import { TableCell } from './TableCell'

export const BirthDateCell: Component<LifeRowCellProps & { startDate: number }> = (props) => {
    const store = useStore()
    const formatBirthDate = () => formatDmyDate(props.life?.dateOfBirth)

    const [isFocus, setIsFocus] = createToggleSignal(false)
    const [birthDateFormatted, setBirthDateFormatted] = createSignal(props.life?.dateOfBirth ? formatBirthDate() : '')

    const validateDateOfBirthCss = () => {
        if (isFocus() || (!useStore().isFullValidation() && !birthDateFormatted())) {
            return ''
        }

        if (birthDateFormatted().length === DATEBOX_TEXT_LENGTH) {
            const bdAsEpoch = parseDmyDate(birthDateFormatted()).getTime()
            return isValidDob(bdAsEpoch, props.startDate, props.life.type) ? '' : policyRowStyles.invalidField
        }

        return policyRowStyles.invalidField
    }

    const errorMessage = () => {
        if (isFocus()) {
            return ''
        }

        if (birthDateFormatted().length === DATEBOX_TEXT_LENGTH) {
            const dobAsEpoch = parseDmyDate(birthDateFormatted()).getTime()
            const invalid = !isValidDob(dobAsEpoch, props.startDate, props.life.type)

            if (invalid) {
                return props.life.type === 'DEPENDANT' ? LIFE_WARNING_ERROR_MESSAGES['DOB_DEPENDANT_AGE'] : LIFE_WARNING_ERROR_MESSAGES['DOB_PRIMARY_SECONDARY_AGE']
            }
        }

        return ''
    }

    const onBlur = () => {
        setBirthDateFormatted(props.life?.dateOfBirth ? formatBirthDate() : '')
        props.onBlur?.()
        setIsFocus()
    }

    return <TableCell tdStyles={classList(tableStyles.dob, policyRowStyles.inputOverflow)} errorMessage={errorMessage()}>
        <DateBox placeholder={'dd/mm/yyyy'}
                 selectedDate={props.life.dateOfBirth}
                 onChange={setBirthDateFormatted}
                 revertOnBlurIfInvalid
                 onBlur={onBlur}
                 onFocus={setIsFocus}
                 onDateEntered={dateOfBirth => store.updateLife(props.policyIndex, props.life.id, {dateOfBirth})}
                 class={validateDateOfBirthCss()}
                 readonly={props.readonly}
        />
    </TableCell>
}