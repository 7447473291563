import { useServicePatch } from '@peachy/client-kit'
import { Policy } from '@peachy/core-domain-pure'
import { PolicyTypes, QuoteResponse } from '@peachy/quote-pure'
import { constructQuoteRequest, IQuoteValidator, QuoteRequest, QuoteServiceClient } from '@peachy/quote-solid-client'
import { Draft, newUUID, Valid } from '@peachy/utility-kit-pure'
import { debounce } from '@solid-primitives/scheduled'
import { subYears } from 'date-fns'
import { createContext, createEffect, createSignal, mergeProps, ParentComponent, Show, useContext } from 'solid-js'
import { Spinner } from '../../global/icons/Spinner/Spinner'
import { useStore } from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import { getQuoteStore } from '../../providers/AccountSubscription/session/stores'

const QuoteContext = createContext<QuoteServiceClient>()

const [isBusy, setIsBusy] = createSignal<boolean>(false)

export const QuoteController: ParentComponent<{ canQuote?: boolean }> = (propsIn) => {
    const props = mergeProps({ canQuote: true }, propsIn)

    const store = useStore()
    const quoteStorage = getQuoteStore()

    const canQuote = () => props.canQuote && !store.isEditing()

    const [quoteClient, setQuoteClient] = createSignal<QuoteServiceClient>()

    const validator: IQuoteValidator = {
        assertIsValidDraft(quote: Draft<QuoteRequest>): asserts quote is Valid<QuoteRequest> {
            if (!this.isValidDraft()) {
                throw 'Invalid Quote'
            }
        },

        isValidDraft(quote: Draft<QuoteRequest>): quote is Valid<QuoteRequest> {
            return store.hasValidSmeLifeCount()
        },

        isValidFinal(quote: Draft<QuoteRequest>): quote is Valid<QuoteRequest> {
            return store.isValidPolicies()
        }
    }

    const quoteHandler = () => {
        //default address and dob if missing
        const policies = store.getPoliciesAssignedToPlans().map(policy => {
            const lifeId = Object.keys(policy.lives)[0]
            const life = Object.values(policy.lives)[0]

            return ({
                ...policy,
                lives: {
                    [lifeId]: {
                        ...life,
                        address: (life.address ?? store.getCompany()?.address),
                        dateOfBirth: (life.dateOfBirth ?? subYears(new Date, 30).getTime())
                    }
                }
            } as Policy)
        })

        const quote = constructQuoteRequest({
            quoteRequestId: newUUID(),
            plans: store.getPlans(),
            policies: policies, 
            version: 2,
            groupSize: policies.length,
            type: PolicyTypes.SME
        })

        quoteClient().canUpdateQuote() ? quoteClient().updateQuote(quote) : quoteClient().updateDraft(quote)
        quoteClient().tryQuote()
    }

    setQuoteClient(new QuoteServiceClient(useServicePatch(), validator))

    const quote = () => {
        debounce(quoteHandler, 500)()
    }
    
    //this "listens" for any of the referenced property changes - if they do, trigger a quote
    createEffect(() => {
        if (!canQuote()) {
            return
        }

        setIsBusy(true)

        //plan -> life changes
        store.getLives().map(life => `${life.planId}:${life.id}`)

        //plan benefit/excess changes
        store.getPlans().flatMap(plan => `${plan.benefits}:${plan.excess?.amountInPence}`)

        //policy start date changes
        store.getSubscriptionStartDate()

        quote()
    })
    
    createEffect(() => {
        if (quoteClient().currentQuoteState() === 'Valid') {
            quoteStorage.save(quoteClient().currentQuoteResponse())
        }

        setIsBusy(quoteClient().isBusy())
    })

    return (
        <Show when={quoteClient()} fallback={<Spinner />}>
            <QuoteContext.Provider value={quoteClient()}>
                {props.children}
            </QuoteContext.Provider>
        </Show>
    )
}

export function getQuote(): Draft<QuoteResponse> {
    return useContext(QuoteContext).currentQuoteResponse()
}

export function isQuoteLoading() {
    return isBusy()
}