import { IStore } from './IStore'

export class SessionStorage<T> implements IStore<T> {
    constructor (private readonly key: string) {}

    save (obj: T) {
        sessionStorage.setItem(this.key, JSON.stringify(obj as T))
    }

    get (): T {
        return JSON.parse(sessionStorage.getItem(this.key)) as T
    }

    delete () {
        sessionStorage.removeItem(this.key)
    }

    exists (): boolean {
        return !!(sessionStorage.getItem(this.key))
    }
    
}