import {PolicyType} from '../core/PolicyType'
import {Plan, Policy} from '@peachy/core-domain-pure'


export class QuoteRequest {
    quoteRequestId: string
    version?: number
    type?: PolicyType
    groupSize?: number
    plans: Plan[]
    policies: Policy[]
    renewalPeriodMonths?: number
    annualBilling?: boolean
}
