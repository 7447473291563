
export default function () {

    const common = {
        stroke: '#1d1d1b',
        'stroke-linecap': 'round',
        'stroke-miterlimit': 10,
        'stroke-width': '2px'
    }

    const class1 = {
        ...common,
        fill: '#fff'
    }

    const class2 = {
        ...common,
        fill: 'none'
    }

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.94 75.96">
            <path style={class2} d="m48.69,16.74c2.5,1.47,8.5,5.71,9.94,9.71,1.13,3.16.5,10.86,3.67,20.68,1.76,5.47,3.56,6.53,5.89,9.25,1.83,2.14,3.68,3.53,4.91,4.66" />
            <path style={class2} d="m34.61,36.87c3.6,2.68,3.1,4.03,5.19,5.94.62.56,1.84.3,2.03-.46" />
            <path style={class2} d="m42.82,46.75c-.57,1.55-2.24,1.19-2.78.38-2.27-3.39-2.27-3.04-4-5.02-.65-.74-.94-1.37-1.09-2.22-.19-1.06-.15-1.26-.12-2.75" />
            <rect style={class1} x="27.14" y="7.44" width="18.67" height="29.21" rx="1.57" ry="1.57" transform="translate(9.22 -10.72) rotate(19.09)" />
            <polygon style={class2} points="37.92 37.99 35.44 37.14 35.44 37.14 44.9 9.52 47.38 10.37 47.38 10.37 37.92 37.99" />
            <path style={class1} d="m52.36,35.74c-2.8-4.88-3.58-8.68-4.44-11.13-.75-2.12-1.06-3.13-1.85-5.04-1.66-4.02-2.57-4.47-3.03-4.59-1.21-.31-2.88.13-3.44,1.25-.31.63-.43,1.7.44,5.45.64,2.79,1.08,4.82,1.13,5,1.17,4.66,1.79,8.82,1,12.63-.62,2.96.7,5.88,2.13,9.26,1.27,3.01,2.54,4.38,5.82,8.82,1.67,2.26,4.03,5.55,6.75,9.68" />
        </svg>
    )
} 
