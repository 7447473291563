import { Component } from 'solid-js'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { PlanConfiguration } from '../../../components/PlanBuilder/PlanConfiguration/PlanConfiguration'
import { PlanMembersCounter } from './PlanMemberCounter'

export const QuoteSummary: Component = () => {
    const store = useStore()

    store.setPlanEditable(false)
    store.setViewMode('QUOTE')

    return (<>
        <PlanConfiguration/>
        <PlanMembersCounter/>
    </>)
}