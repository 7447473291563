import * as PlanConfig from '@peachy/product-pure'
import { ConfigurablePlans } from './ConfigurablePlans'
import { Optional } from '@peachy/utility-kit-pure'
import { useAppConfig } from '../../../config/configureApp'

/**
 * This takes the stored plan config and maps it to ConfigurablePlans (basically the plan config with the stored plan data layered on top)
 * 
 * ConfigurablePlans is the model used by the UI
 */
export function getAllPlans (): ConfigurablePlans.Plan[] {
    const config = useAppConfig().plans

    const mapBenefits = (plan: PlanConfig.Plan) => config.benefits.map((benefitDefn) => { 
        const availableBenefit = plan.availableBenefits[benefitDefn.id] || {} as PlanConfig.AvailableBenefit
        const isSupported = (availableBenefit?.isEditable !== undefined)
        
        return {
            ...benefitDefn, 
            ...availableBenefit,
            isSupported,
            isActive: isSupported && availableBenefit.include !== false
        }
    })
    
    const mapExcess = (plan: PlanConfig.Plan): Optional<ConfigurablePlans.Excess> => {
        if(plan.excess?.id) {
            const excess: PlanConfig.Excess = config.excess[plan.excess.id]

            return {
                ...excess,
                ...plan.excess
            } as ConfigurablePlans.Excess
        }
    }

    return config.plans.map((plan) => ({
            id: plan.id,
            name: plan.name,
            benefits: mapBenefits(plan),
            excess: mapExcess(plan)
        } as ConfigurablePlans.Plan)
    )
}

export function getPlan (id: string): ConfigurablePlans.Plan {
    return getAllPlans().find(plan => plan.id === id)
}

export function getAllBenefits (): ConfigurablePlans.Benefit[] {
    return getAllPlans()[0].benefits
}

export function getBenefitForPlan (id: string, benefitId: string): ConfigurablePlans.Benefit {
    return getPlan(id).benefits.find(benefit => benefit.id === benefitId)
}

export function getBenefitTypesForPlan (id: string, benefitId: string): string[] {
    return getBenefitForPlan(id, benefitId).benefitTypes
}
