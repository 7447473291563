import {makeMaker} from './src/mixin/mixin'
import {QuoteRequest, QuoteResponse} from '@peachy/quote-pure'
export * from './src/QuoteServiceClient'
export * from './scripts/policy'

export {QuoteRequest, QuoteResponse}

export const constructQuoteRequest = makeMaker(QuoteRequest)

        import * as me from '@peachy/quote-solid-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/comp-kit/quote/solid-client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/quote-solid-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    