import { createToggleSignal } from '@peachy/client-kit'
import { Plan as PlanDomain, Policy } from '@peachy/core-domain-pure'
import { Dictionary, classList, currencyFromPence } from '@peachy/utility-kit-pure'
import { Component, createEffect, createSignal, For, ParentComponent, Show } from 'solid-js'
import { Toggle } from '../../../global/forms/Toggle/Toggle'
import { getBenefitIcon } from '../../../global/icons/benefits/BenefitIcons'
import { getAddOnIcon } from '../../../global/icons/addons/Icons'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { markdown } from '../../../service/markdown/MarkdownService'
import { MoreInfoModal } from '../../Modal/MoreInfoModal'
import { ConfigurablePlans } from '../domain/ConfigurablePlans'
import { getAllBenefits, getPlan } from '../domain/plan-utils'
import styles from './PlanConfiguration.module.css'
import { ExcessLimit } from '../shared/ExcessLimit'
import { AnnualLimit } from '../shared/AnnualLimit'
import BookIcon from '../../../global/icons/Book'
import { DownloadSamplePlan } from '../shared/DownloadSamplePlan'
import { getPolicies } from '../../Home/HomeProvider'

const getStore = () => useStore()

const Title: ParentComponent = (props) => <h6 class={styles.title}>{props.children}</h6>

const MoreInfoIcon = () => <i class="icon-info"></i>

//benefit card components
const BenefitOption: Component<{ title: string, benefitId: string, onClick?: () => void }> = (props) => (
    <div class={styles.benefitOption} onClick={props.onClick}>
        <span>{getBenefitIcon(props.benefitId)}</span>
        <span class={styles.name}>{props.title}</span>
        <span class={styles.moreInfo}><MoreInfoIcon/></span>
    </div>
)

const BenefitOptionModal: Component<{ benefitOption: ConfigurablePlans.Benefit }> = (props) => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    return (
        <>
            <BenefitOption title={props.benefitOption.name} benefitId={props.benefitOption.id} onClick={toggleIsOpen}/>
            <MoreInfoModal isOpen={isOpen()} onDismiss={toggleIsOpen}>
                <h3>{props.benefitOption.name}</h3>
                <section innerHTML={markdown(props.benefitOption.moreInfo)}/>
            </MoreInfoModal>
        </>
    )
}

const BenefitOptions: Component<{ benefitOptions: ConfigurablePlans.Benefit[] }> = (props) => {
    return (
        <div class={styles.card}>
            <Title>Benefit</Title>
            <For each={props.benefitOptions}>
                {(option) => <BenefitOptionModal benefitOption={option}/>}
            </For>
        </div>
    )
}

const ExcessOption: Component = () => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    return (
        <div class={styles.card}>
            <div class={styles.excessOption} onClick={toggleIsOpen}>
                <span>{getAddOnIcon('EXCESS')}</span>
                <span class={styles.name}>{'EXCESS'}</span>
                <span class={styles.moreInfo}><MoreInfoIcon/></span>
            </div>
            <MoreInfoModal isOpen={isOpen()} onDismiss={toggleIsOpen}>
                <h3>EXCESS</h3>
                <section
                    innerHTML={markdown("You can choose to add an excess to your employees' plans. The overall excess applies to:\n- Mental Health, Consultations & Diagnostics and Hospital Care benefits\n- Each individual on cover per year")}/>
            </MoreInfoModal>
        </div>
    )
}

const SamplePlanOption: Component = () => {
    return (
        <div class={styles.card}>
            <div class={styles.samplePlanOption}>
                <span><BookIcon /></span>
                <span class={styles.name}>DOWNLOAD SAMPLE MEMBER GUIDE</span>
                <span class={styles.moreInfo}></span>
            </div>
        </div>
    )
}

//plan card components


const Boolean: Component<BenefitValueProps> = (props) => {
    const store = getStore()
    const planBenefit = store.getBenefit(props.planId, props.benefitConfig.id)

    const [state, setState] = createSignal(planBenefit !== undefined)

    if (props.benefitConfig.isEditable) {

        const setToggle = (newState: boolean) => {
            setState(newState)

            if (newState) {
                store.addBenefit(props.planId, props.benefitConfig.id)
            } else {
                store.removeBenefit(props.planId, props.benefitConfig.id)
            }
        }

        return (
            <Show when={props.isEditable} fallback={<>{state() ? 'Included' : 'Not included'}</>}>
                <Toggle state={state()} setState={setToggle}/>
            </Show>
        )
    }

    return <>{props.benefitConfig.include ? 'Included' : ''}</>
}

const FixedAnnualLimit:  Component<BenefitValueProps> = (props) => { 
    const limitExists = !!props.benefitConfig.defaultLimit
    const isNumber = typeof props.benefitConfig.defaultLimit === 'number'
    
    return <Show when={limitExists && isNumber}>
        <p class={styles.optionalBenefitLimit}>Annual limit {currencyFromPence(props.benefitConfig.defaultLimit as number)}</p>
    </Show>
}

export type BenefitValueProps = {
    benefitConfig: ConfigurablePlans.Benefit, 
    planId: string, 
    isEditable: boolean
}

export type ExcessValueProps = {
    excessConfig: ConfigurablePlans.Excess, 
    planId: string, 
    isEditable: boolean
}

const BenefitValue: Component<BenefitValueProps> = (props) => {
    return (
        <div class={styles.planBenefitValue}>
            <Show when={props.benefitConfig.isSupported}>
                <Show when={props.benefitConfig.type === 'boolean'}>
                    <Boolean {...props} />
                    <FixedAnnualLimit {...props}/>
                </Show>
                <Show when={props.benefitConfig.type === 'select'}>
                    <AnnualLimit {...props} />
                </Show>
            </Show>
        </div>
    )
}

const ExcessValue: Component<ExcessValueProps> = (props) => {
    return (
        <Show when={props.excessConfig} fallback={<div class={styles.transparent}/>}>
            <div class={styles.planExcessValue}>
                <ExcessLimit {...props}/>
            </div>
        </Show>
    )
}

export const PlanCard: ParentComponent<{ planId: string }> = (props) => {
    const [planActive, setPlanActive] = createSignal(false)

    createEffect(() => {
        setPlanActive(getStore().hasSomeLifeForPlan(props.planId))
    })

    const activeCss = () => planActive() ? (getStore().isPlanEditable() ? styles.activeEdit : styles.activeView) : ''

    return (
        <div class={classList(styles.card, styles.planCard, activeCss())}>
            {props.children}
        </div>
    )
}

const Plan: Component<{ plan: PlanDomain, editable: boolean }> = (props) => {
    const planConfig = getPlan(props.plan.configId)

    return (
        <PlanCard planId={props.plan.id}>
            <Title>{props.plan.name}</Title>
            <For each={planConfig.benefits}>
                {(benefitConfig) => <BenefitValue planId={props.plan.id} benefitConfig={benefitConfig} isEditable={props.editable} />}
            </For>
        </PlanCard>
    )
}

const PlanExcess: Component<{ plan: PlanDomain, editable: boolean }> = (props) => {
    const planConfig = getPlan(props.plan.configId)

    return (
        <PlanCard planId={props.plan.id}>
            <ExcessValue planId={props.plan.id} excessConfig={planConfig.excess} isEditable={props.editable} />
        </PlanCard>
    )
}

export const PlanConfiguration: Component = () => {
    const plans = getStore().getPlans() as PlanDomain[]
    const editablePlans: Dictionary<boolean> = plans.reduce((map, plan) => {
        map[plan.id] = canEditPlan(plan.id)
        return map
    }, {})

    return (
        <>
            {/* Plan cards */}
            <div class={styles.planConfig}>
                <BenefitOptions benefitOptions={getAllBenefits()}/>
                <For each={plans}>
                    {(plan) => <Plan plan={plan} editable={editablePlans[plan.id]}/>}
                </For>
            </div>

            {/* Excess */}
            <div class={classList(styles.planConfig, styles.planExcessConfig)}>
                <ExcessOption/>
                <For each={plans}>
                    {(plan) => <PlanExcess plan={plan} editable={editablePlans[plan.id]}/>}
                </For>
            </div>

            {/* Sample plans */}
            <div class={classList(styles.planConfig, styles.planSampleConfig)}>
                <SamplePlanOption/>
                <For each={plans}>
                    {(plan) => <DownloadSamplePlan plan={plan}/>}
                </For>
            </div>

        </>
    )
}

const canEditPlan = (planId: string) => {
    const store = getStore()
    const isEditable = store.isPlanEditable()

    if (store.getViewMode() === 'PORTAL' && isEditable) {
        return !getPolicies().some((p: Policy) => p.getPrimaryLife().planId === planId)
    }

    return isEditable
}