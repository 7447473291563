import { Address, Company, DraftAccount, Life, LifeType, PaymentDetails, Policy } from '@peachy/core-domain-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { createDraftLife, createDraftPolicy } from '../create-subscription-requests'
import { OtherPropertiesStore, ViewMode } from './OtherPropertiesStore'
import { PlanLifeAssignmentStore } from './PlanLifeAssignmentStore'
import { SubscriptionRequestStore } from './SubscriptionRequestStore'
import { batch, mergeProps } from 'solid-js'
import { getQuoteStore } from '../session/stores'
import { SubscriptionRequestRepository } from '../../../service/subscription/SubscriptionRequestRepository'

export const BENCH_ID = 'bench'
const MIN_EMPLOYEES = 2
const MAX_EMPLOYEES = 50

export class AccountSubscriptionStore {
    private subscriptionRequestStore: SubscriptionRequestStore
    private planLifeAssignmentStore: PlanLifeAssignmentStore
    private otherPropertiesStore: OtherPropertiesStore

    public onPortalCancel: () => void
    public onPortalEdit: () => void

    constructor (readonly subscriptionRequestRepository: SubscriptionRequestRepository) {
        this.subscriptionRequestStore = new SubscriptionRequestStore(subscriptionRequestRepository)
        this.planLifeAssignmentStore = new PlanLifeAssignmentStore(this.getPlans(), this.getLives())
        this.otherPropertiesStore = new OtherPropertiesStore(['Unassigned'].concat(this.getPlanNames()))
    }

    public reset () {
        getQuoteStore()?.delete()
        this.subscriptionRequestRepository.delete()
    }

    public assignLifeToPlan (params: AssignLifeToPlanParams) {
        const defaults = {
            toPlanId: BENCH_ID,
            fromPlanId: BENCH_ID,
            orderIndex: 0
        }

        const paramsWithDefaults = mergeProps(defaults, params)

        batch(() => {
            if (params.type === 'PRIMARY') {
                this.updateLifeOrder(paramsWithDefaults.fromPlanId, paramsWithDefaults.toPlanId, paramsWithDefaults.lifeId, paramsWithDefaults.orderIndex)
            }

            this.updateLife(
                paramsWithDefaults.policyIndex ?? this.getPolicyIndexForLife(paramsWithDefaults.lifeId),
                paramsWithDefaults.lifeId,
                { planId: paramsWithDefaults.toPlanId === BENCH_ID ? undefined : paramsWithDefaults.toPlanId }
            )
        })
    }

    public assignPolicyToPlan (params: AssignPolicyToPlanParams) {
        batch(() => {
            Object.values(params.policy.lives).forEach(life => {
                this.assignLifeToPlan({
                    lifeId: life.id,
                    fromPlanId: params.fromPlanId,
                    policyIndex: params.policyIndex,
                    toPlanId: params.toPlanId,
                    orderIndex: params.orderIndex,
                    type: life.type
                })
            })
        })
    }

    public getSubscriptionRequest() {
        return this.subscriptionRequestStore.getSubscriptionRequest()
    }

    public canAddPolicy() {
        return this.subscriptionRequestStore.getPolicies().length < MAX_EMPLOYEES
    }

    public canRemovePolicy() {
        return this.subscriptionRequestStore.getPolicies().length > MIN_EMPLOYEES
    }

    public getUser() {
        return this.subscriptionRequestStore.getUser()
    }

    public getAccount(): DraftAccount {
        return this.subscriptionRequestStore.getAccount()
    }

    public getCompany() {
        return this.subscriptionRequestStore.getCompany()
    }

    public getSubscriptionStartDate() {
        return this.subscriptionRequestStore.getSubscriptionStartDate()
    }

    public getPolicy(id : string) {
        return this.subscriptionRequestStore.getPolicies().find(p => p.id === id)
    }

    public getPolicies() {
        return this.subscriptionRequestStore.getPolicies()
    }

    public getPolicyForLife(lifeId: string) {
        return this.subscriptionRequestStore.getPolicyForLife(lifeId)
    }

    public getPolicyIndexForLife(lifeId: string) {
        return this.subscriptionRequestStore.getPolicyIndexForLife(lifeId)
    }

    public getPoliciesAssignedToPlans() {
        return this.getPolicies().filter(policy => Boolean(Object.values(policy.lives)[0].planId))
    }

    public hasValidSmeLifeCount() {
        return this.getPoliciesAssignedToPlans().length > 1
    }

    public canPurchase() {
        return this.hasValidSmeLifeCount() && this.isValidPolicies() && this.isEmailsUnique()
    }

    public getLives() {
        return this.subscriptionRequestStore.getLives()
    }

    public getLivesForPlan(planId: string) {
        return this.subscriptionRequestStore.getLivesForPlan(planId)
    }

    public hasSomeLifeForPlan(planId: string) {
        return this.subscriptionRequestStore.hasSomeLifeForPlan(planId)
    }

    public getLivesForPlanOrdered(planId?: string) {
        return this.planLifeAssignmentStore.orderLives(planId ?? BENCH_ID, this.subscriptionRequestStore.getLivesForPlan(planId))
    }

    public getPlans() {
        return this.subscriptionRequestStore.getPlans()
    }

    public getPlansInSelector() {
        return this.otherPropertiesStore.getPlansInSelector()
    }

    public getPlanById(id: string) {
        return this.subscriptionRequestStore.getPlans().find(p => id === p.id)
    }

    public getPlanIds() {
        return this.subscriptionRequestStore.getPlans().map(p => p.id)
    }

    public getPlanNames() {
        return this.subscriptionRequestStore.getPlans().map(p => p.name)
    }

    public getOldestDateOfBirthForLives(youngestAge: number, filter: (life: Draft<Life>) => boolean) {
        return this.subscriptionRequestStore.getOldestDateOfBirthForLives(youngestAge, filter)
    }

    public getBenefitLimit(planId: string, benefitId: string) {
        return this.getBenefit(planId, benefitId).limit
    }

    public getBenefit(planId: string, benefitId: string) {
        return this.subscriptionRequestStore.getBenefit(planId, benefitId)
    }

    public getExcess(planId: string) {
        return this.subscriptionRequestStore.getExcess(planId)
    }

    public updateUserName(firstName: string, lastName: string) {
        this.subscriptionRequestStore.updateUserName(firstName, lastName)
    }

    public updateCompany(company: Company) {
        this.subscriptionRequestStore.updateCompany(company)
    }

    public updateEmail(email: string) {
        this.subscriptionRequestStore.updateEmail(email)
    }

    public updateMarketingAccepted(marketingAccepted: boolean) {
        this.subscriptionRequestStore.updateMarketingAccepted(marketingAccepted)
    }

    public updatePoliciesAccepted(policiesAccepted: boolean) {
        this.subscriptionRequestStore.updatePoliciesAccepted(policiesAccepted)
    }

    public updateUserCognitoId(cognitoUserId: string) {
        this.subscriptionRequestStore.updateUserCognitoId(cognitoUserId)
    }

    public addPolicy(planId = BENCH_ID) {
        const newPolicy = createDraftPolicy(this.getSubscriptionStartDate(), planId === BENCH_ID ? undefined : planId)
        this.subscriptionRequestStore.addPolicy(newPolicy)
        this.planLifeAssignmentStore.addLifeOrder(planId, getPrimaryLife(newPolicy).id)
    }

    public removeLife(policyIndex: number, lifeId: string) {
        this.subscriptionRequestStore.removeLife(policyIndex, lifeId)
    }

    public removePolicy(removeIndex: number) {
        this.subscriptionRequestStore.removePolicy(removeIndex)
    }

    public isValidPolicies() {
        return this.subscriptionRequestStore.isValidPolicies()
    }

    public isEmailsUnique() {
        const emails = this.getLives().map(l => l.email.toLowerCase())
        return emails.length === new Set(emails).size
    }

    public isEmailUnique(email: string): boolean {
        const emailToCompare = email.toLowerCase()
        return this.getLives().filter(l => l.email.toLowerCase() === emailToCompare).length > 1
    }

    public updateStartDate(startDate: number) {
        return this.subscriptionRequestStore.updateStartDate(startDate)
    }

    public addNonPrimaryLife(policyIndex: number, planId: string, type: LifeType, address : Draft<Address>) {
        const life = createDraftLife(planId, type, address)
        this.subscriptionRequestStore.updateLife(policyIndex, life.id, life)
    }

    public updateLife(policyIndex: number, lifeId: string, fields: Draft<Life>) {
        this.subscriptionRequestStore.updateLife(policyIndex, lifeId, fields)
    }

    public updateLivesForPolicy(policyIndex: number, policy: Draft<Policy>, fields: Draft<Life>) {
        batch(() => {
            Object.values(policy.lives).forEach(life => this.updateLife(policyIndex, life.id, fields))
        })
    }

    public updateLifeOrder(fromPlanId: string, toPlanId: string, lifeId: string, orderIndex: number) {
        this.planLifeAssignmentStore.updateLifeOrder(fromPlanId, toPlanId, lifeId, orderIndex)
    }

    public addBenefit(planId: string, benefitId: string) {
        this.subscriptionRequestStore.addBenefit(planId, benefitId)
    }

    public removeBenefit (planId: string, benefitId: string) {
        this.subscriptionRequestStore.removeBenefit(planId, benefitId)
    }

    public updateBenefitLimit(planId: string, benefitId: string, limit: number) {
        this.subscriptionRequestStore.updateBenefitLimit(planId, benefitId, limit)
    }

    public updatePlanExcess(planId: string, limit: number) {
        this.subscriptionRequestStore.updatePlanExcess(planId, limit)
    }

    public updatePayment(paymentDetails: Draft<PaymentDetails>) {
        this.subscriptionRequestStore.updatePayment(paymentDetails)
    }

    public updateBillingAddress(hasSeparateAddress: boolean, address: Draft<Address>) {
        this.subscriptionRequestStore.updateBillingAddress(hasSeparateAddress, address)
    }

    public getPaymentDetails() {
        return this.subscriptionRequestStore.getPaymentDetails()
    }

    public startEditing() {
        this.otherPropertiesStore.setIsEditing(true)
    }

    public stopEditing() {
        this.otherPropertiesStore.setIsEditing(false)
    }

    public isEditing() {
        return this.otherPropertiesStore.getIsEditing()
    }

    public setFullValidation(value: boolean) {
        this.otherPropertiesStore.setIsFullValidation(value)
    }

    public isFullValidation() {
        return this.otherPropertiesStore.getIsFullValidation()
    }

    public setPlanEditable(value: boolean) {
        this.otherPropertiesStore.setIsPlanEditable(value)
    }

    public isPlanEditable() {
        return this.otherPropertiesStore.getIsPlanEditable()
    }

    public setViewMode(viewMode: ViewMode) {
        this.otherPropertiesStore.setViewMode(viewMode)
    }

    public getViewMode() {
        return this.otherPropertiesStore.getViewMode()
    }

    public getMinimumPoliciesCount() {
        return MIN_EMPLOYEES
    }

    public canEditLife(lifeId: string) {
        return !this.otherPropertiesStore.getLockedLives()?.find((life: Life) => life.id === lifeId)
    }

    public getLockLives() {
        return this.otherPropertiesStore.getLockedLives()
    }

    public setLockedLives(lives: Life[]) {
        this.otherPropertiesStore.setLockedLives(lives)
    }

    public getDeletedLives() {
        return this.getPlans().flatMap(p => this.getDeletedLivesForPlan(p.id))
    }

    public getDeletedLivesForPlan (planId: string) {
        const activeLives = this.getLivesForPlan(planId)
        const existingLives = this.otherPropertiesStore.getLockedLivesForPlan(planId)
        return existingLives?.filter((lockedLife: Life) => !activeLives.find((life: Life) => life.id === lockedLife.id))
    }
}

// TODO where to put this. Used in many places
export function getPrimaryLife(policy: Draft<Policy>): Draft<Life> {
    return getLife(policy, 'PRIMARY')
}

export function getSecondaryLife(policy: Draft<Policy>): Draft<Life> {
    return getLife(policy, 'SECONDARY')
}

export function getDependants(policy: Draft<Policy>): Draft<Life>[] {
    return Object.values(policy.lives).filter(l => l.type === 'DEPENDANT')
}

function getLife(policy: Draft<Policy>, type: LifeType) {
    return Object.values(policy.lives).find(l => l.type === type)
}

type AssignLifeToPlanParams = {
    lifeId: string,
    fromPlanId: string,
    policyIndex?: number,
    toPlanId?: string,
    orderIndex?: number
    type?: LifeType
}

type AssignPolicyToPlanParams = {
    policy: Draft<Policy>,
    fromPlanId: string,
    policyIndex?: number,
    toPlanId?: string,
    orderIndex?: number
}