import { createSignal } from 'solid-js'
import { CompanyTypeahead } from '../../../components/Company/CompanyTypeahead'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { validateDraftAccount } from '../../../service/validation/ValidationService'
import { CaptureForm } from '../CaptureForm'

export function CaptureAdminCompany () {
    const store = useStore()

    const [selectedCompany, setSelectedCompany] = createSignal(store.getCompany() ?? undefined)

    const onSubmit = (event) => {
        event.preventDefault()
        store.updateCompany(selectedCompany())
    }

    return (
        <CaptureForm
            onSubmit={onSubmit}
            errorFields={[ 'company' ]}
            validateFields={() => validateDraftAccount({
                ...store.getAccount(),
                company: selectedCompany()
            })}
            autocomplete="off"
        >
            <h4>Nice to meet you {store.getUser().firstname}<br/>Let's find your company!</h4>
            {/* Is chat to us underline */}
            <p>If you're a charity please <a target={'_blank'} onClick={() => Intercom('show')}>chat to us</a></p>
            <fieldset>
                <CompanyTypeahead 
                    company={selectedCompany()}
                    setCompany={setSelectedCompany}
                    autoFocus
                    placeholder='Enter whole words to see results'
                />
            </fieldset>

        </CaptureForm>
    )
}
