import { KeyboardController, Spinner, useServicePatch } from '@peachy/client-kit'
import { GeoLocationServiceController } from '@peachy/client-kit/src/services/geo-location/GeoLocationServiceController'
import { createContext, useContext } from 'solid-js'
import { createStore } from 'solid-js/store'
import { AppServiceProvider } from './AppServiceProvider'

type AppContextValue = {
    isLoading: () => boolean
    setLoading: (value: boolean) => void
}

const AppContext = createContext<AppContextValue>()

export const AppContextProvider = (props) => {
    const [store, setStore] = createStore({ 
        loading: false
    })

    const isLoading = () => store.loading
    const setLoading = (loading: boolean = false) => setStore('loading', loading)

    return (
        <AppContext.Provider value={{ isLoading, setLoading } as AppContextValue}>
            <AppServiceProvider>
                <GeoLocationServiceController servicePatchUrl={useServicePatch()}>
                    <KeyboardController>
                        <>
                            {props.children}
                            <Spinner isShown={isLoading()}/>
                        </>
                    </KeyboardController>
                </GeoLocationServiceController>
            </AppServiceProvider>
        </AppContext.Provider>
    )
}

export function useAppContext() {
    return useContext(AppContext)
}