import { Spinner } from '@peachy/client-kit'
import { Outlet, useNavigate } from '@solidjs/router'
import { Show, createEffect, createResource } from 'solid-js'
import { useIamService } from '../../providers/AppServiceProvider'
import { HomeProvider } from './HomeProvider'
import { loadActiveSubscription } from './loadActiveSubscription'
import { loadSubscriptionRequest } from './loadSubscriptionRequest'
import { DashboardHeader } from '../Header/DashboardHeader/DashboardHeader'
import { Page } from '../../Page'
import { AccountSubscriptionDto } from '@peachy/core-domain-pure'

export function Home() { 
    const navigate = useNavigate(), iamService = useIamService()

    const [subscriptionRequest] = createResource(loadSubscriptionRequest)
    const [currentAccountSubscription] = createResource(loadActiveSubscription)

    const isLoading = () => subscriptionRequest.loading || currentAccountSubscription.loading

    createEffect(() => {
        // route guard
        if (!iamService.isSignedIn()) {
            navigate('/quote', { replace: true })
        } 

        // in progress quote
        if (!currentAccountSubscription() && subscriptionRequest()) {
            navigate('/quote/plans', { replace: true })
        }

        // cancelled subscription
        if(currentAccountSubscription()?.subscription.status === 'CANCELLED') {
            navigate('/billing', { replace: true })
        }
    })

    return (
        <>
            <Show when={!isLoading() && currentAccountSubscription()}>
                <HomeProvider accountSubscription={currentAccountSubscription()}>
                    <Page header={<DashboardHeader/>}>
                        <Outlet/>
                    </Page>
                </HomeProvider>
            </Show>
            <Show when={isLoading()}>
                <Spinner isShown={true}/>
            </Show>
        </>
    )
}

export const NavigateToHome = (sub: AccountSubscriptionDto) => {
    const navigate = useNavigate()
    if (isSignedIn() && sub) {
        navigate('/', { replace: true })
    }
}
