import { AddressLookup as ClientKitAddressLookup, AddressLookupProps } from '@peachy/client-kit'
import { Component } from 'solid-js'
import styles from './AddressLookup.module.css'

type Props = Omit<AddressLookupProps, 'appearance'> & {
    isValid: () => boolean
    errorClass?: string
}

export const AddressLookup: Component<Props> = (props) => {
    return <ClientKitAddressLookup 
                {...props} 
                appearance={{ 
                    lookupField: props.isValid() ? '' : (props.errorClass ?? styles.invalidField),
                    listItem: styles.addressItem,
                    list: styles.addressList,
                    selectedItem: styles.selectedAddress
                }}
            />
}