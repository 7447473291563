import styles from './BenefitIcons.module.css'

export default function () {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.74 89.81">
            <polygon class={styles.line} points="61.18 41.13 61.18 42.13 70.9 42.13 70.9 70.35 61.18 70.35 61.18 70.97 34.01 70.97 34.01 70.35 24.29 70.35 24.29 42.13 34.01 42.13 34.01 41.13 23.29 41.13 23.29 71.35 71.9 71.35 71.9 41.13 61.18 41.13"/>
            <polygon class={styles.line} points="34.51 52.6 34.51 29.98 60.68 29.98 60.68 52.6 61.68 52.6 61.68 28.98 33.51 28.98 33.51 52.6 34.51 52.6"/>
            <polygon class={styles.line} points="60.68 57.45 60.68 70.35 34.51 70.35 34.51 57.45 33.51 57.45 33.51 71.35 61.68 71.35 61.68 57.45 60.68 57.45"/>
            <path class={styles.line} d="M57.92,30H37.27a.51.51,0,0,1-.5-.5V26.87a.49.49,0,0,1,.26-.43l10.32-5.87a.48.48,0,0,1,.49,0l10.32,5.87a.49.49,0,0,1,.26.43v2.62A.51.51,0,0,1,57.92,30ZM37.77,29H57.42V27.16L47.6,21.58l-9.83,5.58Z"/>
            <path class={styles.line} d="M49.51,36.19v3.05h3.05V43H49.51v3H45.73V43h-3V39.24h3V36.19h3.78m0-1.37H45.73a1.37,1.37,0,0,0-1.38,1.37v1.67H42.68a1.38,1.38,0,0,0-1.38,1.38V43a1.38,1.38,0,0,0,1.38,1.38h1.67v1.67a1.38,1.38,0,0,0,1.38,1.38h3.78a1.38,1.38,0,0,0,1.38-1.38V44.4h1.67A1.37,1.37,0,0,0,53.93,43V39.24a1.37,1.37,0,0,0-1.37-1.38H50.89V36.19a1.37,1.37,0,0,0-1.38-1.37Z"/>
            <path class={styles.line} d="M65.33,58H29.91V52.1H65.33ZM30.91,57H64.33V53.1H30.91Z"/>
        </svg>
    )
}

