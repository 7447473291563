import { Component, For } from 'solid-js'
import { PolicyRow } from './PolicyRow'
import styles from './ManageMembersTable.module.css'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { AddressChangeStoreProvider } from '../store/AddressChangeStore'


export const ManageMembersTable: Component = () => {
    const store = useStore()

    return (
        <table class={styles.table}>
            <thead>
            <tr>
                <th class={styles.index}></th>
                <th class={styles.name}>First Name</th>
                <th class={styles.name}>Last Name</th>
                <th class={styles.dob}>Date Of Birth</th>
                <th class={styles.address}>Address</th>
                <th class={styles.email}>Email</th>
                <th class={styles.plan}>Plan</th>
                <th class={styles.dependants}></th>
                <th class={styles.delete}></th>
            </tr>
            </thead>
            <AddressChangeStoreProvider>
                <tbody>
                    <For each={store.getPolicies()}>{(policy, index) => {
                        return <PolicyRow policy={policy} policyIndex={index()}/>
                    }
                    }</For>
                </tbody>
            </AddressChangeStoreProvider>
        </table>
    )
}
