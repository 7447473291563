import { Policy } from "@peachy/core-domain-pure"
import { Draft } from "@peachy/utility-kit-pure"
import { Component, createMemo } from "solid-js"
import { useStore } from "../../../../providers/AccountSubscription/AccountSubscriptionProvider"
import { getPrimaryLife } from "../../../../providers/AccountSubscription/stores/AccountSubscriptionStore"
import { FIRST_NAME_PLACEHOLDER } from '../../../PlanBuilder/PlanAssignment/PlanEmployeeAssignment'
import tableStyles from '../ManageMembersTable.module.css'
import { AddDependants } from "../cell/AddDependants"
import { AddressCell } from "../cell/AddressCell"
import { BirthDateCell } from "../cell/BirthDateCell"
import { EmailCell } from "../cell/EmailCell"
import { FirstNameCell } from "../cell/FirstNameCell"
import { LastNameCell } from "../cell/LastNameCell"
import { PlanSelector } from "../cell/PlanSelector"
import { RemovePolicy } from "../cell/RemovePolicy"

export const PrimaryLifeRow: Component<{
    policy: Draft<Policy>,
    policyIndex: number
}> = (props) => {
    const store = useStore()

    const primaryLife = getPrimaryLife(props.policy)

    const cellProps = createMemo(() => ({
        life: primaryLife,
        policyId: props.policy.id,
        policyIndex: props.policyIndex,
        readonly: !store.canEditLife(primaryLife.id)
    }))

    return (
        <tr>
            <td class={tableStyles.index}>{props.policyIndex + 1}.</td>
            <FirstNameCell {...cellProps()} placeholder={FIRST_NAME_PLACEHOLDER} />
            <LastNameCell {...cellProps()} />
            <BirthDateCell {...cellProps()} startDate={props.policy.startDate} />
            <AddressCell {...cellProps()} />
            <EmailCell {...cellProps()} />
            <PlanSelector {...cellProps()} />
            <AddDependants {...cellProps()} policy={props.policy} />
            <RemovePolicy {...cellProps()} />
        </tr>
    )
}