import { DraftAccount, isValidProperty, Life, LifeType, Policy, toClass, User, validate } from '@peachy/core-domain-pure'
import { Draft, isAgeBefore, isAgeInRange } from '@peachy/utility-kit-pure'

export const validateUser = (user: User) => validate(toClass(user, User), undefined, User)
export const validateDraftAccount = (account: DraftAccount) => validate(toClass(account, DraftAccount), undefined, DraftAccount)

export const MIN_AGE = 16
export const MAX_AGE = 64

export const DEPENDANT_MIN_AGE = 0
export const DEPENDANT_MAX_AGE = 25
export const DEPENDANT_MIN_AGE_EMAIL_REQUIRED = 16

export const LIFE_WARNING_ERROR_MESSAGES = {
    'EMAIL_FORMAT' : 'Please enter a valid email address',
    'EMAIL_UNIQUE': 'Please enter unique email addresses for all members',
    'DOB_DEPENDANT_AGE': `Children must be under ${DEPENDANT_MAX_AGE} on plan start date`,
    'DOB_PRIMARY_SECONDARY_AGE': `Must be between ${MIN_AGE} and ${MAX_AGE} on plan start date`,
    'ADDRESS' : 'Address not recognised'
}

export const isValidDob = (dateOfBirth: number, policyStartDate: number, type: LifeType) => {
    switch(type) {
        case 'PRIMARY':
        case 'SECONDARY':
            return isAgeInRange(dateOfBirth, policyStartDate, MIN_AGE, MAX_AGE)
        case 'DEPENDANT':
            return isAgeInRange(dateOfBirth, policyStartDate, DEPENDANT_MIN_AGE, DEPENDANT_MAX_AGE)
        default:
            return false
    }
}

export const validateLifeField = (life: Draft<Life>, field: keyof Draft<Life>) => isValidProperty(life, field, null, Life)

export const validatePolicy = (policy: Draft<Policy>, fullValidation: boolean = true) => {
    const validateDob = (l: Draft<Life>) => (l.dateOfBirth ? isValidDob(l.dateOfBirth, policy.startDate, l.type) : false)
    const fieldsToValidate = (life: Draft<Life>) => {
        if (life.type === 'DEPENDANT' && life.dateOfBirth && isAgeBefore(life.dateOfBirth, policy.startDate, DEPENDANT_MIN_AGE_EMAIL_REQUIRED)) {
            return ['firstname', 'lastname', 'dateOfBirth', 'address']
        }

        return ['firstname', 'lastname', 'dateOfBirth', 'address', 'email']
    }
    
    return Object.values(policy.lives).every(life => {
        const errorMap = validate(life, null, Life)
        const validFields = !fieldsToValidate(life).some(f => errorMap?.[f])

        // TODO Add required annotation to Life Model
        const hasPlanId = life.planId
        // only validate dob if this is a full validation check
        const validDob = fullValidation ? validateDob(life) : true
    
        return validFields && validDob && hasPlanId
    })
}
