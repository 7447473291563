import tableStyles from '../ManageMembersTable.module.css'
import { useStore } from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { Component, Show, createMemo } from 'solid-js'
import { LifeRowCellProps } from '../PolicyRow'
import { LifeType, Policy } from '@peachy/core-domain-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { getSecondaryLife } from '../../../../providers/AccountSubscription/stores/AccountSubscriptionStore'
import AddPartner from '../../../../global/icons/AddPartner'
import AddChild from '../../../../global/icons/AddChild'

export const AddDependants: Component<LifeRowCellProps & { policy: Draft<Policy> }> = (props) => {
    const store = useStore()

    const secondLife = () => getSecondaryLife(props.policy)
    const address = createMemo(() => props.life.address)
    const addNonPrimaryLife = (type: LifeType) => store.addNonPrimaryLife(props.policyIndex, props.life.planId, type, address())

    return <td class={tableStyles.dependants}>
        <Show when={!secondLife()} fallback={<span />}>
            <span title="Add partner for this employee" class={tableStyles.addBtn} onClick={() => addNonPrimaryLife('SECONDARY')}>
                <AddPartner />
            </span>
        </Show>
        <span title="Add child for this employee" class={tableStyles.addBtn} onClick={() => addNonPrimaryLife('DEPENDANT')}>
            <AddChild />
        </span>
    </td>
}