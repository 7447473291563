import {Plan, Policy} from '@peachy/core-domain-pure'

export class QuoteResponse {
    quoteRequestId: string
    quoteResponseId: string
    timestamp: number
    plans: Plan[]
    policies: Policy[]
    totalMonthlyPremium: number
    totalAnnualPremium: number
}