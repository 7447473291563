import {onMount} from 'solid-js'
import {JSX} from 'solid-js'
import { classList } from '@peachy/utility-kit-pure'
import styles from './TextBox.module.css'

export type TextBoxProps = JSX.InputHTMLAttributes<HTMLInputElement> &  {
    autoFocus?: boolean
}

export function TextBox (props: TextBoxProps) {
    let htmlElement

    onMount(() => {
        if (props.autoFocus) {
            htmlElement.focus()
        }
    })

    return (
        <label class={classList(styles.label, props.class)}>
            <input 
                {...props}
                type={props.type ?? 'text'}
                ref={htmlElement}
                class={styles.textBox}
            />
            {props.children}
        </label>
    )
}