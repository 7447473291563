import {BenefitType} from './BenefitType'
import {ExcessDiscount} from './ExcessDiscount'

export type PricingBreakdown = {
    a_baseRiskCost: BenefitPriceBreakdown
    b_adjustedForCorrelation: BenefitPriceBreakdown
    c_adjustedForContingency: BenefitPriceBreakdown
    d_adjustedForLossRatio: BenefitPriceBreakdown
    e_adjustedForIpt: BenefitPriceBreakdown
    f_adjustedForDiscounts: BenefitPriceBreakdown
    g_adjustedForPriceResolution: BenefitPriceBreakdown
}

export type BenefitPriceBreakdown = {
    [_ in BenefitType | ExcessDiscount]: number
}