import { classList } from '@peachy/utility-kit-pure'
import { Component, ParentComponent, Show } from 'solid-js'
import { ROOT_DOMAIN_URL } from '../../App'
import { editSubscriptionSessionStore, subscriptionRequestSessionStore } from '../../providers/AccountSubscription/session/stores'
import { useIamService } from '../../providers/AppServiceProvider'
import styles from './Header.module.css'

export const Header: ParentComponent<{ class?: string }> = (props) => {

    const classes = classList(styles.header, props.class)

    return <header class={classes}>
        {props.children}
    </header>
}

export const LogoutLink: Component = () => {
    const iamService = useIamService()

    const logout = () => {
        subscriptionRequestSessionStore().delete()
        editSubscriptionSessionStore().delete()
        iamService.signOut(ROOT_DOMAIN_URL)
    }

    return (
        <Show when={iamService.isSignedIn()} fallback={<></>}>
            <a onClick={logout}>Log out</a>
        </Show>
    )
}