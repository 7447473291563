import { Component, createSignal, onMount } from 'solid-js'
import { StripeElements, StripeElementType } from '@stripe/stripe-js'
import styles from './CardElements.module.css'

const createElement = (props: CardElementProps) => {
    const elementsStyle = {
        base: {
            color: '#31325F',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
        
            '::placeholder': {
                color: '#CFD7E0'
            }
        }
    }
    
    //@ts-ignore
    const element = props.stripeElements.create(props.type, { style: elementsStyle, placeholder: props.placeholder, ...props.options })
    element.on('change', (event) => props.onChange?.(event))
    return element
}

interface CardFieldProps {
    stripeElements: StripeElements
    placeholder: string
    onChange?: (event) => void
}

interface CardElementProps extends CardFieldProps {
    type: StripeElementType
    options?: any
}

const CardElement: Component<CardElementProps> = (props) => {
    const [hasFocus, setHasFocus] = createSignal(false)

    onMount(() => {
        const element = createElement(props)
        element.on('focus', () => setHasFocus(true))
        element.on('blur', () => setHasFocus(false))
        element.mount(`#${props.type}`)
    })
 
    return <div id={props.type} class={hasFocus() ? styles.focus : ''}></div> 
}

export const CardNumber: Component<CardFieldProps> = (props) => {
    return <CardElement {...props} type="cardNumber" options={{showIcon: true}} />
}

export const CardExpiry: Component<CardFieldProps> = (props) => {
    return <CardElement {...props} type="cardExpiry" />
}

export const CardCvc: Component<CardFieldProps> = (props) => {
    return <CardElement {...props} type="cardCvc" />
}
