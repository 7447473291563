import { AccountDto, SmeEmployerPolicyDocData, Subscription, SubscriptionDto, toClass } from '@peachy/core-domain-pure'
import { ROOT_DOMAIN } from '../../../App'
import { getAccountSubscription } from '../../../components/Home/HomeProvider'
import { useDocGenService } from '../../../providers/AppServiceProvider'
import { Component } from 'solid-js'

const createEmployerDocTicket = (account: AccountDto, subscription: SubscriptionDto) => (
    {
        templateName: 'SmeEmployerPolicyDoc',
        docData: {
            domain: ROOT_DOMAIN,
            subscriptionStartDate: subscription.startDate,
            planNumber: toClass(subscription, Subscription).referenceNumber(),
            plans: subscription.plans,
            policies: subscription.policies,
            company: account.company,
            email: subscription.contactEmail
        } as SmeEmployerPolicyDocData
    }
)

export const DownloadCompanyPlan: Component<{class?: string}> = (props) => {
    const accountSubscription = getAccountSubscription(), docGenService = useDocGenService()

    const download = async () => {
        const ticket = createEmployerDocTicket(accountSubscription.account, accountSubscription.subscription)
        const link = await docGenService.getPdfLink(ticket, `PeachyPlan-${accountSubscription.account.company.name}.pdf`)

        window.open(link, `_CompanyPlanPdf`)
    }

    return (
        <a class={props.class} onClick={download}>View</a>
    )
}