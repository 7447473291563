import { QuoteResponse } from '@peachy/quote-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { SessionStorage } from '../../../global/storage/SessionStorage'
import { useStore } from '../AccountSubscriptionProvider'

function getStoreForKey(key: string) {
    return new SessionStorage<Draft<QuoteResponse>>(key)
}

export function subscriptionRequestSessionStore() {
    return getStoreForKey('subscriptionRequest')
}

export function editSubscriptionSessionStore() {
    return getStoreForKey('editSubscription')
}

export function getQuoteStore() {
    const subscriptionRequestId = useStore().getSubscriptionRequest().subscription.id
    return getStoreForKey(`quote_${subscriptionRequestId}`)
}
