import { NOOP } from '@peachy/utility-kit-pure'
import { useLocation, useNavigate } from '@solidjs/router'
import { isFinite } from 'lodash-es'
import { createEffect, createSignal } from 'solid-js'
import { PageRouterPath } from './PageRouterPath'

const [currentPageIndex, setCurrentPageIndex] = createSignal(0)
const [maxPageIndex, setMaxPageIndex] = createSignal(0)
const [routes, setRoutes] = createSignal([])
const [hasRequestedNextPage, setHasRequestedNextPage] = createSignal(false)
const [hasRequestedPreviousPage, setHasRequestedPreviousPage] = createSignal(false)
export const hasPreviousPage = () => currentPageIndex() > 0
export const hasNextPage = () => currentPageIndex() < maxPageIndex()
export const nextPage = () => (hasNextPage()) && setHasRequestedNextPage(true)
export const previousPage = () => (hasPreviousPage()) && setHasRequestedPreviousPage(true)


export const toPage = (routes : PageRouterPath[], pathname: string) => {
    const pageIndex = routes.findIndex((route) => route.path === pathname)
    isFinite(pageIndex) ? setCurrentPageIndex(pageIndex) : NOOP
}

export interface PageRouterProps {
    routes: PageRouterPath[]
}

/**
 * This function determines which route the user can access.
 * 
 * @param props
 */
function determineAccessibleRoute (props: PageRouterProps) {
    const { routes } = props
    
    const requestedRouteIndex = routes.findIndex((route) => route.path === useLocation().pathname)
    
    // determine if the user can access the requested route - if not, find the first page that they CAN access
    const route = routes.slice(0, requestedRouteIndex+1).toReversed().find((route) => route.canAccess())
    const index = props.routes.indexOf(route)

    setCurrentPageIndex(index !== -1 ? index : 0)
}

export function PageRouter (props: PageRouterProps) {
    const navigate = useNavigate()

    setRoutes(props.routes)
    setMaxPageIndex(routes().length - 1)
    
    determineAccessibleRoute(props)
    
    const currentPage = () => routes()[currentPageIndex()]

    createEffect(() => {
        if (hasRequestedPreviousPage()) {
            setCurrentPageIndex(currentPageIndex() - 1)
            setHasRequestedPreviousPage(false)
        }
    })

    createEffect(() => {
        if (hasRequestedNextPage()) {
            setCurrentPageIndex(currentPageIndex() + 1)
            setHasRequestedNextPage(false)
        }
    })

    //if the route has changed, navigate to it
    createEffect(() => {
        if (isFinite(currentPageIndex())) {
            navigate(currentPage().path as string, { replace: true })
        }
    })

    return <>
        {currentPage().component}
    </>
}