import { Component } from 'solid-js'
import { currencyFromPence } from '@peachy/utility-kit-pure'
import { InfoPanelNextPaymentDate } from '../pure/InfoPanelNextPaymentDate'
import { InfoPanelPlanRenewalDate } from '../pure/InfoPanelPlanRenewalDate'
import { Subscription } from '@peachy/core-domain-pure'

export const BillingPaymentDetailsPanel: Component<{ subscription: Subscription }> = (props) => {
    return (
        <div>
            <h5>{currencyFromPence(props.subscription.totalMonthlyPremium)}</h5>
            <InfoPanelNextPaymentDate nextPaymentDate={props.subscription.getNextPaymentDate()}/>
            <InfoPanelPlanRenewalDate startDate={new Date(props.subscription.startDate)} renewalDate={props.subscription.getAnnualRenewalDate()} />
        </div>
    )
}