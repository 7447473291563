import { useNavigate } from '@solidjs/router'
import { Button } from '../../../global/forms/Button'
import { Component, ParentComponent, Show } from 'solid-js'
import { getSubscription } from '../../../components/Home/HomeProvider'
import { Price } from '../../../components/FormattedPrice/Price'
import { getQuoteStore } from '../../../providers/AccountSubscription/session/stores'
import { FormattedPrice } from '../../../components/FormattedPrice/FormattedPrice'
import { Card } from '../../../components/FormattedPrice/Card/Card'
import styles from './Summary.module.css'
import SummaryOfChanges from './SummaryOfChanges'

export default () => {
    const navigate = useNavigate()

    const onBack = () => navigate('/plans')
    const onUpdate = () => console.log('update plan!')

    return (
        <div class={styles.Summary}>
            <header>
                <hgroup>
                    <h4>Almost done!</h4>
                    <h4>Please review your plan changes</h4>
                </hgroup>
            </header>

            <Section title="Summary of changes">
                <SummaryOfChanges/>
            </Section>

            <Section title="Cost">
                <Cost />
            </Section>
            
            <Section>
                <h3>Are you happy with these changes?</h3>
                <div class={styles.ButtonContainer}>
                    <Button theme="secondary" onClick={onBack}>Back to edit</Button>
                    <Button theme="primary" onClick={onUpdate}>Update plan</Button>
                </div>
            </Section>

        </div>
    )
}

const Section: ParentComponent<{ title?: string }> = (props) => (
    <section class={styles.Content}>
        <Show when={props.title}><h5>{props.title}</h5></Show>
        {props.children}
    </section>
)

const Cost: Component = () => {
    const { totalMonthlyPremium: currentPremium } = getSubscription()
    const { totalMonthlyPremium: newPremium } = getQuoteStore().get()

    return <>
        <p>You currently pay <b><Price value={currentPremium} /> per month</b>. Following these plan changes and provided you don’t make further changes, you premium will now be:</p>
        <div class={styles.Premiums}>
            <Card class={styles.NewPremium}>
                <FormattedPrice price={newPremium} />
            </Card>
        </div>
        <div class={styles.PremiumText}>
            <p>These changes will take effect immediately once you confirm below.</p>
            <p>Your <b>next payment</b> will be adjusted on a pro rata basis to account for changes made mid-month.</p>
            <p>Any <b>new members</b> on plan will receive an email with their plan documents and instructions to download the Peachy app.</p>
            <p>Anyone who has been <b>removed</b> from the plan will receive an email notifying them that their plan has been cancelled.</p>
        </div>
    </>
}