import { CapturePaymentDetails } from '../CapturePaymentDetails/CapturePaymentDetails'
import styles from './Summary.module.css'
import { BusinessDetails } from './BusinessDetails'
import { PlanDetails } from './PlanDetails'
import { classList } from '@peachy/utility-kit-pure'

const PaymentDetails = () => <div class={styles.DetailsContainer}><CapturePaymentDetails/></div>

export const Summary = () => {
    return (
        <div class={styles.Summary}>
            <header class={classList(styles.WhiteSection, styles.Heading)}>
                <hgroup class={styles.DetailsContainer}>
                    <h4>Almost there!</h4>
                    <h4>Please review your plan below</h4>
                </hgroup>
            </header>

            <section class={styles.WhiteSection}>
                <BusinessDetails/>
            </section>

            <section>
                <PlanDetails/>
            </section>

            <section class={styles.WhiteSection}>
                <PaymentDetails/>
            </section>
        </div>
    )
}