import styles from './QuoteFooter.module.css'
import {Button} from '../../../global/forms/Button'
import {PriceBreakdownModal} from './PriceBreakdownModal'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {nextPage} from '../../PageRouter/PageRouter'
import {createToggleSignal} from '@peachy/client-kit'
import { ManageMembersModal } from '../../ManageMembers/modal/ManageMembersModal'

export const QuoteFooter = () => {

    const [isEmployeeModalOpen, toggleEmployeeModal] = createToggleSignal(false)

    const store = useStore()

    const onOpen = () => {
        store.setFullValidation(true)

        if (!store.canPurchase()) {
            store.startEditing()
            toggleEmployeeModal()
            return
        }

        nextPage()
    }

    const onClose = () => {
        toggleEmployeeModal()
        store.stopEditing()
    }

    return (
        <section class={styles.quoteFooter}>
            <PriceBreakdownModal/>
            <Button theme="light" onClick={onOpen}>Let's go →</Button>
            <ManageMembersModal isOpen={isEmployeeModalOpen()} onDismiss={onClose} />
        </section>
    )
}
