import { SubscriptionRequest } from '@peachy/core-domain-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { ParentComponent, Show, createSignal, mergeProps } from 'solid-js'
import { IStore } from '../../global/storage/IStore'
import { SubscriptionRequestRepository } from '../../service/subscription/SubscriptionRequestRepository'
import { useSubscriptionService } from '../AppServiceProvider'
import { AccountSubscriptionStore } from './stores/AccountSubscriptionStore'

interface Props {
    store: IStore<Draft<SubscriptionRequest>>
    saveToServer?: boolean
}

const [store, setStore] = createSignal<AccountSubscriptionStore>()

// TODO: rename as no longer a Provider but just a component
export const AccountSubscriptionProvider: ParentComponent<Props> = (propsIn) => {

    const props = mergeProps({saveToServer: false}, propsIn)

    const subscriptionService = props.saveToServer ? useSubscriptionService() : undefined

    setStore(new AccountSubscriptionStore(new SubscriptionRequestRepository(props.store, subscriptionService)))

    return (
        <Show when={store()}>
            {props.children}
        </Show>
    )
}

export const useStore = () => store()
