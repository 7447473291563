import { Life, Policy } from '@peachy/core-domain-pure'
import { Draft } from '@peachy/utility-kit-pure'
import { Component } from 'solid-js'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { validateLifeField } from '../../../service/validation/ValidationService'
import styles from './PolicyRow.module.css'
import { DependantRows } from './row/DependantRows'
import { PrimaryLifeRow } from './row/PrimaryLifeRow'
import { SecondLifeRow } from './row/SecondLifeRow'

export const validateRequiredFieldCss = (life: Draft<Life>, field: keyof Draft<Life>) => validateLifeField(life, field) ? '' : styles.invalidField
export const validateOptionalFieldCss = (life: Draft<Life>, field: keyof Draft<Life>) => life[field] && !validateLifeField(life, field) ? styles.invalidField : ''
export const isValidOptionalField = (life: Draft<Life>, field: keyof Draft<Life>) => life[field] && validateLifeField(life, field)

export const validateFieldCss = (life: Draft<Life>, field: keyof Draft<Life>) => {
    const store = useStore()
    return store.isFullValidation() ? validateRequiredFieldCss(life, field) : validateOptionalFieldCss(life, field)
}
export type LifeRowCellProps = {
    life?: Draft<Life>
    policyId?: string
    policyIndex?: number
    readonly?: boolean
    placeholder?: string
    onBlur?: () => void
}

export const PolicyRow: Component<{
    policy: Draft<Policy>,
    policyIndex: number
}> = (props) => {
    
    return (
        <>
            <PrimaryLifeRow policy={props.policy} policyIndex={props.policyIndex} />
            <SecondLifeRow policy={props.policy} policyIndex={props.policyIndex} />
            <DependantRows policy={props.policy} policyIndex={props.policyIndex} />
        </>
    )
}
