import { AccountSubscriptionDto, Policy, toClass } from '@peachy/core-domain-pure'
import { ParentComponent, createContext, useContext } from 'solid-js'
import { createStore } from 'solid-js/store'

type ContextState = {
    accountSubscription: AccountSubscriptionDto
}

type ContextValue = [
    state: ContextState,
    actions: {
        getAccountSubscription(): AccountSubscriptionDto
    }
]

const HomeContext = createContext<ContextValue>()

export const HomeProvider: ParentComponent<{ accountSubscription: AccountSubscriptionDto }> = (props) => {
    const [state] = createStore({
        accountSubscription: props.accountSubscription,
    })

    const getAccountSubscription = () => state.accountSubscription

    return (
        <HomeContext.Provider value={[state, { getAccountSubscription }] as ContextValue}>
            {props.children}
        </HomeContext.Provider>
    )
}


export const getHomeContext = () => useContext(HomeContext)

export function getAccountSubscription() {
    const [_, { getAccountSubscription: getAccountSub }] = getHomeContext()
    return getAccountSub()
}

export function getAccount() {
    return getAccountSubscription().account
}

export function getPolicies() {
    return getAccountSubscription()?.subscription.policies.map(p => toClass(p, Policy))
}

export function getPrimaryLives() {
    return getPolicies()?.map(policy => policy.getPrimaryLife())
}

export function getLives() {
    return getPolicies()?.flatMap(policy => Object.values(policy.lives))
}

export function getSubscription() {
    return getAccountSubscription().subscription
}

export function getPolicy(policyId: string) {
    return getPolicies()?.find(p => p.id === policyId)
}