import { PopupListSelector as ClientKitPopupListSelector, PopupListSelectorProps } from '@peachy/client-kit'
import styles from './PopupListSelector.module.css'
import { Component } from 'solid-js'
import { classList } from '@peachy/utility-kit-pure'

export const PopupListSelector: Component<PopupListSelectorProps> = (props) => {
    return <ClientKitPopupListSelector
        list={props.list}
        onSelect={props.onSelect}
        onDismiss={props.onDismiss}
        selection={props.selection}
        enabled={props.enabled}
        locatorElement={props.locatorElement}
        appearance={{
            list: classList(styles.list, props.appearance?.list),
            listItem: classList(styles.listItem, props.appearance?.listItem),
            selectedItem: classList(styles.selectedItem, props.appearance?.selectedItem)
        }}
    />
}
