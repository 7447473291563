import { User } from '@peachy/core-domain-pure'
import { Link } from '@solidjs/router'
import { Show, createSignal } from 'solid-js'
import { TextBox } from '../../../global/forms/TextBox/TextBox'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { validateUser } from '../../../service/validation/ValidationService'
import { CaptureForm } from '../CaptureForm'
import { useIamService } from '../../../providers/AppServiceProvider'

export function CaptureAdminName() {
    const store = useStore()
    const iamService = useIamService()

    const [firstName, setFirstName] = createSignal(store.getUser().firstname ?? '')
    const [lastName, setLastName] = createSignal(store.getUser()?.lastname ?? '')

    const onSubmit = async (event) => {
        event.preventDefault()
        store.updateUserName(firstName(), lastName())
    }

    return (
        <CaptureForm
            onSubmit={onSubmit}
            errorFields={['firstname', 'lastname']}
            validateFields={() => validateUser({
                firstname: firstName(),
                lastname: lastName()
            } as User)}
            autocomplete="off"
        >
            <h4>We'll get you an awesome price in seconds. Let's go!</h4>

            <fieldset>
                <TextBox name="firstname" placeholder="First name" value={firstName()} onInput={(e) => setFirstName(e.target.value)} autoFocus/>
                <TextBox name="lastname" placeholder="Last name" value={lastName()} onInput={(e) => setLastName(e.target.value)}/>
            </fieldset>

            <Show when={!iamService.isSignedIn()}>
                <p>Already have an account? <Link href="/login">Log in here</Link></p>
            </Show>

        </CaptureForm>
    )
}

