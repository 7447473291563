import { Address, Company } from '@peachy/core-domain-pure'
import { Component } from 'solid-js'
import { SubHeader } from '../pure/SubHeader'
import { InfoPanelCreditCardDetails } from '../pure/InfoPanelDirectDebitDetails'
import { InfoPanelBillingAddressDetails } from '../pure/InfoPanelBillingAddressDetails'

export const BillingCompanyDetailsPanel: Component<{ company: Company, billingAddress?: Address  }> = (props) => {
    return (
        <div>
            <SubHeader heading={props.company?.name} onEditClick={() => Intercom('showNewMessage', 'I want to change my company information')} />
            <InfoPanelBillingAddressDetails  company={props.company} billingAddress={props.billingAddress}/>
            <InfoPanelCreditCardDetails/>
        </div>
    )
}
