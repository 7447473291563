import styles from './BenefitIcons.module.css'

export default function () {
    const class2 = { fill: '#020202' }

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.6 90.63">
            <path class={styles.line} d="M72.56,65H22.26a3.86,3.86,0,0,1-3.86-3.86V31.36a3.86,3.86,0,0,1,3.86-3.86h50.3a3.86,3.86,0,0,1,3.86,3.86V61.18A3.86,3.86,0,0,1,72.56,65ZM22.26,28.5a2.87,2.87,0,0,0-2.86,2.86V61.18A2.87,2.87,0,0,0,22.26,64h50.3a2.87,2.87,0,0,0,2.86-2.86V31.36a2.86,2.86,0,0,0-2.86-2.86Z"/>
            <path class={styles.line} d="M69,61.62H36.31A3.64,3.64,0,0,1,32.67,58V34.55a3.64,3.64,0,0,1,3.64-3.63H69a3.64,3.64,0,0,1,3.64,3.63V58A3.65,3.65,0,0,1,69,61.62ZM36.31,31.86a2.7,2.7,0,0,0-2.7,2.69V58a2.7,2.7,0,0,0,2.7,2.7H69A2.69,2.69,0,0,0,71.68,58V34.55A2.69,2.69,0,0,0,69,31.86Z"/>
            <path class={styles.line} d="M26.19,61.62A3.76,3.76,0,0,1,22.33,58V34.55a3.87,3.87,0,0,1,7.72,0V58A3.76,3.76,0,0,1,26.19,61.62Zm0-29.76a2.78,2.78,0,0,0-2.86,2.69V58a2.86,2.86,0,0,0,5.72,0V34.55A2.78,2.78,0,0,0,26.19,31.86Z"/>
            <path class={styles.line} style={class2} d="M42.74,55.55,40.89,42.44l-1.84,6.87H34.4a.46.46,0,1,1,0-.91h3.86l2.93-11L43.1,51l.8-2.58,3.64,0h0a.49.49,0,0,1,.5.45.48.48,0,0,1-.5.46l-2.89,0Z"/>
            <path class={styles.line} style={class2} d="M54.45,55.55,52.6,42.44l-1.83,6.87H46.11a.46.46,0,1,1,0-.91H50l2.92-11L54.82,51l.8-2.58,3.64,0a.52.52,0,0,1,.5.45.48.48,0,0,1-.49.46l-2.89,0Z"/>
            <path class={styles.line} style={class2} d="M66.17,55.55,64.31,42.44l-1.83,6.87H57.83a.46.46,0,1,1,0-.91h3.86l2.93-11L66.53,51l.8-2.58,3.64,0h0a.46.46,0,1,1,0,.91l-2.89,0Z"/>
        </svg>
    )
}

