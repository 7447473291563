import { useNavigate } from '@solidjs/router'
import { Component, Match, ParentComponent, Switch, createEffect } from 'solid-js'
import { ROOT_DOMAIN_URL } from '../../App'
import { Page } from '../../Page'
import { WelcomeHeader } from '../../components/Header/WelcomeHeader/WelcomeHeader'
import { ProgressTracker, createProgressTracker } from '../../components/ProgressTracker/ProgressTracker'
import { Button } from '../../global/forms/Button'
import { useStore } from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import { useIamService, useSmeUserService, useSubscriptionService } from '../../providers/AppServiceProvider'
import styles from './Welcome.module.css'

const Welcome = () => {
    const navigate = useNavigate()
    const iamService = useIamService()
    const store = useStore()
    
    const navigateToLogin = () => {
        navigate(iamService.isSignedIn() ? '/' : '/login', { replace: true })
    }

    createEffect(() => {
        if (!store) {
            navigateToLogin()
        }
    })

    return (
        <Page header={<WelcomeHeader />}>
            <div class={styles.WelcomeContainer}>
                <div class={styles.Welcome}>
                    <p>It's official!</p>
                    <h1>WELCOME TO PEACHY</h1>
                    <div class={styles.textContent}>
                        <p>What now? We've emailed you your plan documents and the information you need to log in to manage your company plan</p>
                        <p class={styles.email}>Didn't get an email? <ResendEmail /></p>
                        <p>All members on the plan have all been sent their documents and a link to download the Peachy app.</p>
                    </div>
                    <div class={styles.navigation}>
                        <Button theme={'light'} onClick={navigateToLogin}>Log in to employer portal</Button>
                        <a href={ROOT_DOMAIN_URL} target={'_blank'}><Button theme={'light'}>Back to home</Button></a>
                    </div>
                </div>
            </div>
        </Page>
    )
}

const ResendEmail: Component = () => {
    const store = useStore()
    const smeUserService = useSmeUserService()
    const iamService = useIamService()
    const subscriptionService = useSubscriptionService()

    const reinviteUser = (email: string) => {
        if (iamService.isInState('Anon')) {
            return smeUserService.reinvite(email)
        }
    }

    const resendWelcomeEmail = (email: string) => {
        return subscriptionService.welcomeAdminUser({ email })
    }

    const sendAgain = () => {
        const email = store?.getAccount()?.contactEmail
        return Promise.all([
            reinviteUser(email), 
            resendWelcomeEmail(email)
        ])
    }

    const tracker = createProgressTracker({
        onComplete: (tracker: ProgressTracker) => setTimeout(() => tracker.reset(), 3000)
    })

    const Message: ParentComponent = (props) => <span><b>{props.children}</b></span>

    return (
        <Switch>
            <Match when={tracker.isIdle()}><Button role={'link'} onClick={() => tracker.invoke(sendAgain)}>Send it again →</Button></Match>
            <Match when={tracker.isInProgress()}><Message><i class="fas fa-spinner fa-spin"></i></Message></Match>
            <Match when={tracker.isDone()}><Message><i class="fa-solid fa-check"/> Sent!</Message></Match>
        </Switch>
    )
}

export default Welcome