import {formatISO} from 'date-fns'
import {QuoteRequest} from './QuoteRequest'
import {IndividualQuoteRequest} from '../core/IndividualQuoteRequest'
import {quoteRegionMap} from '../core/QuoteRegion'
import {BenefitOverrides, BENEFITS_WITH_LIMITS, BenefitValues, BenefitType} from '../core/BenefitType'
import {IndividualQuoteResponse} from '../core/IndividualQuoteResponse'
import {Life, LifeBenefit, Plan, Policy} from '@peachy/core-domain-pure'
import {BenefitType as CoreBenefitType, Premium} from '@peachy/core-domain-pure'


/**
 * Keeping IndividualQuoteRequest for now, as an intermediate model to contain all of the required info for quote
 * engine to create a quote for an individual. Could potentially be refactored out at a later date if the engine
 * itself is changed to interact directly with the core domain model objects.
 *
 * Important things to note:
 * - Start dates are taken from life.benefit.startDate if present, otherwise policy.startDate
 * - Setting quoteRequest.annualBilling to true will change all prices to annual, but core domain model has not been
 *   updated to reflect this change
 *
 */
export function buildIndividual(life: Life, policy: Policy, quoteRequest: QuoteRequest): IndividualQuoteRequest {
    const plan = quoteRequest.plans.find(plan => plan.id == life.planId)
    const [requiredBenefits, benefitLimits, benefitOverrides] = mapBenefits(plan, life)

    return {
        lifeId: life.id,
        region: quoteRegionMap[life.address?.region],
        address: {
            addressComponents: [],
            display: life.address?.display,
            location: life.address?.location,
            postcode: life.address?.postcode,
        },
        renewalPeriodMonths: quoteRequest.renewalPeriodMonths,
        birthdate: formatISO(life.dateOfBirth),
        coverStartDate: formatISO(policy.startDate),
        gender: life.gender,
        includeBreakdown: true,
        requiredBenefits,
        benefitLimits,
        benefitOverrides,
        overallExcess: plan.excess?.amountInPence,
        excessBenefits: plan.excess?.benefitTypes?.map(b => toQuoteBenefitType(b))
    }
}

function mapBenefits(plan: Plan, life: Life): [BenefitType[], BenefitValues, BenefitOverrides] {
    const benefitLimits: BenefitValues = {}
    plan.benefits.filter(b => BENEFITS_WITH_LIMITS.includes(toQuoteBenefitType(b.type))).forEach(b => {
        benefitLimits[toQuoteBenefitType(b.type)] = b.limit
    })
    const requiredBenefits: BenefitType[] = plan.benefits.map(b => toQuoteBenefitType(b.type))
    const benefitOverrides: BenefitOverrides = {}
    if (life.benefits) {
        life.benefits.forEach(b => {
            benefitOverrides[toQuoteBenefitType(b.type)] = {coverStartDate: formatISO(b.startDate)}
        })
    }
    return [[...new Set(requiredBenefits)], benefitLimits, benefitOverrides]
}

export function toQuoteBenefitType(benefitType: CoreBenefitType): BenefitType {
    switch (benefitType) {
        case 'VIRTUAL_GP':
            return 'REMOTE_CARE'
        case 'MENTAL_HEALTH_IN':
            return 'MENTAL_HEALTH'
        case 'MENTAL_HEALTH_OUT':
            return 'MENTAL_HEALTH'
        case 'DENTAL':
            return 'DENTAL_OPTICAL'
        case 'OPTICAL':
            return 'DENTAL_OPTICAL'
        default:
            return benefitType as BenefitType
    }
}

export function populatePremiums(life: Life, policy: Policy, individualQuoteResponse: IndividualQuoteResponse, annualBilling: boolean) {
    life.totalMonthlyPremium = annualBilling ? individualQuoteResponse.totalPlanPrice / 12 : individualQuoteResponse.totalPlanPrice

    const includedBenefitTypes = Object.keys(individualQuoteResponse.pricesByBenefit)
        .map(benefit => toCoreBenefitType(benefit as BenefitType))
        .flat()

    life.benefits = life.benefits ?? []
    includedBenefitTypes.forEach(benefit => {
        let lifeBenefit = life.benefits.find(lifeBenefit => lifeBenefit.type == benefit)
        if (!lifeBenefit) {
            lifeBenefit = {
                type: benefit,
                startDate: policy.startDate,
                premium: new Premium()
            } as LifeBenefit
            life.benefits.push(lifeBenefit)
        }
        if (!['OPTICAL', 'MENTAL_HEALTH_OUT'].includes(benefit)) {
            const BenefitType = toQuoteBenefitType(benefit)
            lifeBenefit.premium = new Premium()
            lifeBenefit.premium.baseRiskCost = individualQuoteResponse.benefitPriceBreakdown.a_baseRiskCost[BenefitType]
            lifeBenefit.premium.adjustedForCorrelation = individualQuoteResponse.benefitPriceBreakdown.b_adjustedForCorrelation[BenefitType]
            lifeBenefit.premium.adjustedForContingency = individualQuoteResponse.benefitPriceBreakdown.c_adjustedForContingency[BenefitType]
            lifeBenefit.premium.adjustedForLossRatio = individualQuoteResponse.benefitPriceBreakdown.d_adjustedForLossRatio[BenefitType]
            lifeBenefit.premium.adjustedForIpt = individualQuoteResponse.benefitPriceBreakdown.e_adjustedForIpt[BenefitType]
            lifeBenefit.premium.adjustedForDiscounts = individualQuoteResponse.benefitPriceBreakdown.f_adjustedForDiscounts[BenefitType]
            lifeBenefit.premium.adjustedForPriceResolution = individualQuoteResponse.benefitPriceBreakdown.g_adjustedForPriceResolution[BenefitType]
            lifeBenefit.premium.total = lifeBenefit.premium.adjustedForPriceResolution
        }
    })

}

export function toCoreBenefitType(BenefitType: BenefitType): CoreBenefitType[] {
    switch (BenefitType) {
        case 'DENTAL_OPTICAL':
            return ['DENTAL', 'OPTICAL']
        case 'MENTAL_HEALTH':
            return ['MENTAL_HEALTH_IN', 'MENTAL_HEALTH_OUT']
        case 'REMOTE_CARE':
            return ['VIRTUAL_GP']
        default:
            return [BenefitType] as CoreBenefitType[]
    }
}