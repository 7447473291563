import { Component, createMemo } from 'solid-js'
import styles from '../InfoPanels.module.css'
import { classList } from '@peachy/utility-kit-pure'
import { YourPlanPolicyDetailsPanel } from './YourPlanPolicyDetailsPanel'
import { YourPlanPaymentDetailsPanel } from './YourPlanPaymentDetailsPanel'
import { YourPlanCompanyDetailsPanel } from './YourPlanCompanyDetailsPanel'
import { getAccount, getAccountSubscription, getPolicies, getSubscription } from '../../../../components/Home/HomeProvider'
import { Subscription, toClass } from '@peachy/core-domain-pure'

export const YourPlanInfoPanels: Component<{ class?: string }> = (props) => {
    const subscription = createMemo(() => toClass(getSubscription(), Subscription))

    return (
        <div class={classList(styles.InfoPanel, styles.YourPlanInfoPanel)}>
            <YourPlanPolicyDetailsPanel subscription={subscription()} policies={getPolicies()} />
            <YourPlanPaymentDetailsPanel
                nextPaymentDate={subscription().getNextPaymentDate()}
                totalMonthlyPremium={getAccountSubscription().subscription.totalMonthlyPremium} />
            <YourPlanCompanyDetailsPanel company={getAccount()?.company} />
        </div>
    )
}