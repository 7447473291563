import { currencyFromPence } from '@peachy/utility-kit-pure'
import { Component } from 'solid-js'
import { InfoPanelCreditCardDetails } from '../pure/InfoPanelDirectDebitDetails'
import { InfoPanelNextPaymentDate } from '../pure/InfoPanelNextPaymentDate'
import { SubHeader } from '../pure/SubHeader'

export const YourPlanPaymentDetailsPanel: Component<{ nextPaymentDate: number, totalMonthlyPremium: number }> = (props) => {

    return (
        <div>
            <SubHeader heading={`Premium: ${currencyFromPence(props.totalMonthlyPremium)}`} onEditClick={() => Intercom('showNewMessage', 'I want to change my payment information')} />
            <InfoPanelNextPaymentDate nextPaymentDate={props.nextPaymentDate} />
            <InfoPanelCreditCardDetails/>
        </div>
    )
}