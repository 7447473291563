import { PlanBenefit } from '@peachy/core-domain-pure'
import { ConfigurablePlans } from './ConfigurablePlans'
import { Excess } from '@peachy/core-domain-pure/src/domain/models/Excess'
import { Optional } from '@peachy/utility-kit-pure'

/**
 * Returns a collection of PlanBenefit objects from a plan config benefit
 * 
 * @param benefit 
 * @returns
 */
export function mapToPlanBenefit (benefit: ConfigurablePlans.Benefit): PlanBenefit[] {
    const mapLimit = (benefitType: string) => {
        return typeof benefit.defaultLimit === 'object' ? benefit.defaultLimit[benefitType] : benefit.defaultLimit
    }

    return benefit.benefitTypes.map(benefitType => ({
        type: benefitType,
        limit: mapLimit(benefitType)
    } as PlanBenefit))
}

/**
 * Returns a collection of PlanBenefit objects mapped from plan config benefits.
 * 
 * Note:
 *  - Benefits that are not active are not mapped
 *  - A single Benefit may produce multiple PlanBenefit objects
 *  - The defaultLimit for the benefit is used
 * 
 * @param plan 
 * @returns 
 */
export function mapToPlanBenefits (benefits: ConfigurablePlans.Benefit[]): PlanBenefit[] {
    const planBenefits = benefits.flatMap(benefit => {
        if (!benefit.isActive) return
        return mapToPlanBenefit(benefit)
    })

    return planBenefits.filter(benefit => Boolean(benefit)) //filter out null values
}

export function mapToExcess (excess?: ConfigurablePlans.Excess): Optional<Excess> {
    if (excess) {
        //NOTE: as this'll end up in the store, we can't return this as an actual Excess object since it won't be reactive
        return {
            id: excess.id,
            amountInPence: excess.defaultLimit,
            benefitTypes: excess.benefitTypes
        } as Excess
    }
}