import { Life, LifeBenefit, Plan, Policy, SmeEmployerPolicyDocData, SmeMemberPolicyDocData, toClass } from '@peachy/core-domain-pure'
import { newUUID, toTitleCase } from '@peachy/utility-kit-pure'
import { Component, For } from 'solid-js'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { useDocGenService } from '../../../providers/AppServiceProvider'
import DownloadPlanIcon from '../../../global/icons/Download'
import { PlanCard } from '../PlanConfiguration/PlanConfiguration'
import { ROOT_DOMAIN } from '../../../App'
import styles from '../PlanConfiguration/PlanConfiguration.module.css'

 const createLife = (plan: Plan) => toClass({
    id: newUUID(),
    planId: plan.id,
    benefits: plan.benefits as LifeBenefit[],
    dateOfBirth: (new Date('2000/06/03')).getTime(),
    firstname: 'Your',
    lastname: 'name',
    address: {
        display: 'Your address'
    },
    postcode: 'ABC 123',
    totalMonthlyPremium: 1234,
    type: 'PRIMARY',
    email: `your@email.com`
}, Life)

export const createSamplePlanPolicy = (plan: Plan, startDate: number) => {
    const life = createLife(plan)

    return toClass({
        id: 'TEMP123',
        status: 'ACTIVE',
        startDate: startDate,
        totalMonthlyPremium: life.totalMonthlyPremium,
        lives: {
            [life.id]: life
        }
    }, Policy)
} 

const createPlans = (plan: Plan) => ({
    [plan.id]: plan
})

const createMemberDocTicket = (plan: Plan, startDate: number) => ({
    templateName: 'SmeMemberPolicyDoc',
    docData: {
        policy: createSamplePlanPolicy(plan, startDate),
        plans: createPlans(plan),
        domain: ROOT_DOMAIN,
        isSample: true,
        subscriptionStartDate: startDate,
        company: {
            name: 'Your Company'
        }
    } as SmeMemberPolicyDocData
})

const filename = (plan: Plan) => {
    const planName = toTitleCase(plan.name).replaceAll(' ','')
    return `PeachyMemberPlan-${planName}-SampleLife.pdf`
}

export const DownloadSamplePlan: Component<{ plan: Plan}> = (props) => {
    const store = useStore(), docGenService = useDocGenService()

    const download = async () => {
        const ticket = createMemberDocTicket(props.plan, store.getSubscriptionStartDate())
        const link = await docGenService.getPdfLink(ticket, filename(props.plan))
        
        window.open(link, `_${props.plan.name}MemberSamplePlanPdf`)
    }

    return (
        <PlanCard planId={props.plan.id}>
            <div class={styles.planDownload}>
                <span class={styles.planDownloadIcon} onClick={download}><DownloadPlanIcon /></span>
            </div>
        </PlanCard>
    )
}