export * from './src/model/api/Legacy'
export * from './src/model/api/QuoteRequest'
export * from './src/model/api/QuoteResponse'
export * from './src/model/api/RequestMapper'

export * from './src/model/core/IndividualQuoteRequest'
export * from './src/model/core/IndividualQuoteResponse'
export * from './src/model/core/PolicyType'
export * from './src/model/core/ExcessDiscount'
export * from './src/model/core/BenefitType'
export * from './src/model/core/PricingBreakdown'
export * from './src/model/core/QuoteRegion'

export * from './src/util/Calc'
export * from './src/util/CsvParser'
export * from './src/util/MapUtils'

export * from './src/QuoteApiDefinition'
        import * as me from '@peachy/quote-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/comp-kit/quote/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/quote-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    