import { Modal, ModalProps } from '@peachy/client-kit'
import styles from './DismissibleModal.module.css'
import { classList } from '@peachy/utility-kit-pure'

export function DismissibleModal(props: ModalProps) {

    const modalClasses = classList(styles.modal, props.class)

    return <Modal
        onDismiss={props.onDismiss}
        isOpen={props.isOpen}
        class={modalClasses}
        locatorElement={props.locatorElement}
        center={props.center}
    >
        {props.children}
    </Modal>
}