import {Show, createSignal} from 'solid-js'
import {IamError} from '../components/IamError'
import {IamProps} from '../IamModal'
import { isValidEmail, isValidPassword } from '../../../to-abstract/validate'
import { TextBox } from '../../../global/forms/TextBox/TextBox'
import { PasswordBox } from '../../../global/forms/PasswordBox/PasswordBox'
import { useIamService } from '../../../providers/AppServiceProvider'
import { Button } from '../../../global/forms/Button'


export function SignIn(props: IamProps) {
    const iamService = useIamService()

    const [email, setEmail] = props.email

    const [password, setPassword] = createSignal('')

    const hasCredentials = () => isValidEmail(email()) && isValidPassword(password())

    const signIn = () => {
        if (hasCredentials()) iamService.signIn(email(), password())
    }

    return <>
        <h3>Sign In</h3>
        <IamError/>
        <TextBox value={email()} onInput={(e) => setEmail(e.target.value)} placeholder={'email address'}/>
        <PasswordBox value={password()} onInput={(e) => setPassword(e.target.value)} />
        <Button theme="primary" onClick={signIn} disabled={!hasCredentials()}>Sign in</Button>
        <Show when={iamService.error() !== 'PasswordExpired'}>
            <a onClick={iamService.requestPasswordReset}>Forgotten password? &rarr; </a>
        </Show>
    </>
}


