import {GenderType} from '@peachy/core-domain-pure'
import {QuoteRegion} from './QuoteRegion'
import {BenefitOverrides, BenefitType, BenefitValues} from './BenefitType'


export type IndividualQuoteRequest = {
    lifeId: string
    birthdate: string
    includeBreakdown: boolean
    coverStartDate: string
    renewalPeriodMonths?: number
    gender: GenderType
    region?: QuoteRegion
    address: {
        display: string
        postcode: string
        addressComponents: string[]
        location: {
            lat: number
            lng: number
        }
    }
    requiredBenefits: BenefitType[]
    benefitOverrides?: BenefitOverrides
    benefitLimits: BenefitValues
    overallExcess?: number
    excessBenefits?: BenefitType[]
    applyExcessAtBenefitLevel?: boolean
    benefitLevelExcesses?: BenefitValues
}