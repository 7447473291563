import { Link } from '@solidjs/router'
import { FAQS_URL } from '../../../App'
import { PeachyLogo } from '../../../global/assets/PeachyLogo/PeachyLogo'
import { Header, LogoutLink } from '../Header'
import { getSubscription } from '../../Home/HomeProvider'
import { Show, createMemo } from 'solid-js'
import styles from './DashboardHeader.module.css'

export function DashboardHeader() {
    const isCancelled = createMemo(() => getSubscription().status === 'CANCELLED')

    return <Header class={styles.header}>
        <div>
            <Show when={!isCancelled()} fallback={<span class={styles.headerDisabled}>Your Plan</span>}>
                <Link href={'/'}>Your Plan</Link>
            </Show>
            <Show when={!isCancelled()} fallback={<span class={styles.headerDisabled}>Documents</span>}>
                <Link href={'/documents'}>Documents</Link>
            </Show>
            <Link href={'/billing'}>Billing</Link>
            <Show when={!isCancelled()} fallback={<span class={styles.headerDisabled}>Members</span>}>
                <Link href={'/members'}>Members</Link>
            </Show>
        </div>
        <PeachyLogo/>
        <div>
            <Link href={FAQS_URL} target={'_blank'}>FAQs</Link>
            <LogoutLink />
        </div>
    </Header>
}