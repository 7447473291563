
import { Modal } from '../Modal/Modal'
import { Component } from 'solid-js'
import styles from './Spinner.module.css'

export const Spinner: Component<{ isShown: boolean }> = (props) => {
    const Dot = () => <div class={styles.Dot}></div>

    return (
        <Modal isOpen={props.isShown} class={styles.Modal}>
            <div class={styles.Spinner}>
                <Dot />
                <Dot />
                <Dot />
            </div>
        </Modal>
    )
}