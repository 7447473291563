import styles from './PriceBreakdown.module.css'


export function PriceBreakdownTable() {
    return (
        <table class={styles.priceBreakdown}>
            <thead>
            <tr>
                <th>Breakdown</th>
                <th>£ per month</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Premium</td>
                <td>£XX.xx</td>
                <td></td>
            </tr>
            <tr>
                <td>Insurance premium tax</td>
                <td>£X.xx</td>
                <td></td>
            </tr>
            <tr>
                <th>Total monthly price</th>
                <th>£XX.xx</th>
                <th></th>
            </tr>
            <tr>
                <th>How Peachy makes money</th>
                <th>£ per month</th>
                <td></td>
            </tr>
            <tr>
                <td>Virtual GP</td>
                <td></td>
                <td>£3 per member per month</td>
            </tr>
            <tr>
                <th>Commission</th>
                <th></th>
                <td>20% commission on premium after virtual GP fee. This is to cover everything we do from claims processing, customer service, marketing
                    etc. Best of all - we don't profit from not paying claims!
                </td>
            </tr>
            </tbody>
        </table>
    )
}
