import { Match, Switch, createComputed, createSignal, onMount, lazy } from 'solid-js'
import { getLives } from '../../../components/Home/HomeProvider'
import { QuoteController } from '../../../components/Quote/QuoteController'
import { AccountSubscriptionProvider, useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { QuoteFooter } from './QuoteFooter/QuoteFooter'
import { getSessionStore } from './YourPlanProvider'
import { useLocation, useNavigate } from '@solidjs/router'
import { DashboardPlanBuilder } from './DashboardPlanBuilder'
import styles from './YourPlan.module.css'

const [canQuote, setCanQuote] = createSignal(false)

export const EditPlan = () => {
    const location = useLocation()
    const isSummaryPage = location.pathname.includes('/summary')

    return <AccountSubscriptionProvider store={getSessionStore()}>
        <>
            {createComputed(() => {
                setCanQuote(useStore().hasValidSmeLifeCount() && haveLivesChanged())
                useStore().setLockedLives(getLives())
            })}
        </>

        <Switch>
            <Match when={!isSummaryPage}><PlanBuilder/></Match>
            <Match when={isSummaryPage}><PlanSummary/></Match>
        </Switch>
    </AccountSubscriptionProvider>
}

const PlanSummary = () => {
    const navigate = useNavigate(), store = useStore(), Summary = lazy(() => import('./Summary'))

    // acts as a guard to this page
    onMount(() => {
        if (!canQuote() || !store.canPurchase()) {
            navigate('/plans')
        }
    })

    return <Summary />
}

const PlanBuilder = () => {
    return <QuoteController canQuote={canQuote()}>
        <Content />
        <QuoteFooter showNewPremium={canQuote()}/>
    </QuoteController>
}

const Content = () => {
    return <div class={styles.YourPlan}>
        <h2>Your Plan</h2>
        <DashboardPlanBuilder/>
    </div>
}

const haveLivesChanged = (): boolean => {
    const storedLives = getLives()
    const editedLives = useStore().getLives()

    if (storedLives.length !== editedLives.length) {
        return true
    }

    return storedLives.some((storedLife) => {
        const foundLife = editedLives.find(l => l.id == storedLife.id)
        return (storedLife.planId !== foundLife?.planId)
    })
}