import { createEffect, Show } from 'solid-js'
import styles from './ManageMembersModal.module.css'
import { ModalProps } from '@peachy/client-kit'
import { useStore } from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import { DismissibleModal } from '../../Modal/DismissibleModal'
import { ManageMembersTable } from '../table/ManageMembersTable'
import { Button } from '../../../global/forms/Button'
import { AccountSubscriptionStore, getPrimaryLife } from '../../../providers/AccountSubscription/stores/AccountSubscriptionStore'

const ManageMembersBrief = () => <>
    <p>We can give you an indicative price just by knowing how many members you want in each plan</p>
    <p>To get a final price and buy the plan, we'll need all the details below.</p>
</>

export function ManageMembersModal(props: ModalProps) {
    const store = useStore()

    createEffect(() => {
        if (props.isOpen) {
            addPlaceholderPolicies(store)
        }
    })

    const close = () => {
        props.onDismiss?.()
        removePlaceholderPolicies(store)
    }

    return (
        <DismissibleModal isOpen={props.isOpen} class={styles.modal} onDismiss={close}>
            <section>
                <h3>Add members</h3>

                <Show when={store.isFullValidation()} fallback={<ManageMembersBrief />}>
                    <p>Please complete the highlighted fields so we can give you a final price before you buy.</p>
                    <p>At least 2 employees must be added to the plan.</p>
                </Show>

                <div class={styles.table}>
                    <ManageMembersTable />
                </div>

                <div class={styles.footerContainer}>
                    <div class={styles.add}>
                        <Show when={store.canAddPolicy()} fallback={<div />}>
                            <Button theme="secondary" onClick={() => store.addPolicy()}>Add employee</Button>
                        </Show>
                    </div>
                    <div class={styles.done}>
                        <Button theme="primary" onClick={close}>Update quote</Button>
                    </div>
                </div>
            </section>
        </DismissibleModal>
    )
}

const addPlaceholderPolicies = (store: AccountSubscriptionStore) => {
    if (store.getPolicies().length < store.getMinimumPoliciesCount()) {
        const placeHolderPolicies = store.getMinimumPoliciesCount() - store.getPolicies().length

        for (let i = 0; i < placeHolderPolicies; i++) {
            store.addPolicy()
        }
    }
}

const removePlaceholderPolicies = (store: AccountSubscriptionStore) => {
    if (store.getPolicies().length === store.getMinimumPoliciesCount()) {
        // Tranverse in reverse due to the array indexes being reduced as we remove items
        for (let i = store.getPolicies().length - 1; i >= 0; i--) {
            const life = getPrimaryLife(store.getPolicies()[i])

            if (!life.planId && !life.firstname && !life.lastname && !life.dateOfBirth && !life.address && !life.email) {
                store.removePolicy(i)
            }
        }
    }
}