import { ParentComponent, Show } from 'solid-js'
import styles from './TableCell.module.css'
import { classList } from '@peachy/utility-kit-pure'

export const TableCell: ParentComponent<{ tdStyles: string, errorMessage?: string }> = (props) => {
    return (
        <td title={props.errorMessage} class={props.tdStyles}>
            {props.children}
            <Show when={props.errorMessage}>
                <i class={classList(styles.warning, 'icon-warning')}></i>
            </Show>
        </td>
    )
}