import { createSignal } from 'solid-js'

type ProgressStatus = 'IN_PROGRESS' | 'DONE' | 'ERROR'

export type ProgressTracker = {
    isInProgress: () => boolean
    isDone: () => boolean
    isError: () => boolean
    isIdle: () => boolean
    invoke: (action: Function) => void
    reset: () => void
}

export type ProgressTrackerOptions = {
    onComplete: (tracker: ProgressTracker) => void
}

export function createProgressTracker(options: ProgressTrackerOptions): ProgressTracker {
    const [status, setStatus] = createSignal<ProgressStatus>()

    const start = () => setStatus('IN_PROGRESS')
    const complete = () => setStatus('DONE')

    const tracker = {
        isInProgress: () => status() === 'IN_PROGRESS',
        isDone: () => status() === 'DONE',
        isError: () => status() === 'ERROR',
        isIdle: () => status() === undefined,
        reset: () => setStatus(),
        invoke: async (action: Function) => {
            start()
            await action()
            complete()
            options.onComplete(tracker)
        }
    } as ProgressTracker

    return tracker
}
