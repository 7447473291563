import styles from './Documents.module.css'
import tableStyles from '../DashboardTable.module.css'
import { Component, JSXElement } from 'solid-js'
import { DownloadCompanyPlan } from './DownloadCompanyPlan'
import { getSubscription } from '../../../components/Home/HomeProvider'
import { readableDate } from '@peachy/utility-kit-pure'

const Documents = () => {
    const subscription = getSubscription()
    
    return (
        <div class={tableStyles.ListContainer}>
            <section>
                <h2>Documents</h2>
                <table class={tableStyles.table}>
                    <thead>
                        <tr>
                            <th class={styles.name}>Name</th>
                            <th class={styles.dateIssued}>Date Issued</th>
                            <th/>
                        </tr>
                    </thead>

                    <tbody>
                        <Document name={'Disclosure'} href='../disclosure'/>
                        <Document name={'IPID'} dateIssued={subscription.startDate} href='../ipid'/>
                        <Document name={'Peachy Plan Company Guide'} dateIssued={subscription.startDate} anchor={<DownloadCompanyPlan class={tableStyles.link} />} />
                    </tbody>
                </table>
            </section>
        </div>
    )
}

const Document: Component<{ name: string, dateIssued ?: number, anchor?: JSXElement, href?: string}> = (props) => {
    return (
        <>
            <tr>
                <td class={styles.name}>{props.name}</td>
                <td class={styles.dateIssued}>{readableDate(props.dateIssued)}</td>
                <td class={styles.view}>{props.anchor ?? <a class={tableStyles.link} href={props.href} target={'_blank'}>View</a>}</td>
            </tr>

            {/* Row Gap TODO find a nicer way (Use grid rather than table) */}
            <th class={tableStyles.rowGap} />
        </>
    )
}

export default Documents