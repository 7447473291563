import { AccountSubscriptionProvider } from "../../providers/AccountSubscription/AccountSubscriptionProvider";
import { subscriptionRequestSessionStore } from '../../providers/AccountSubscription/session/stores';
import { QuoteAndBuy } from "./Quote";

const QuoteAndBuyProvider = () => {
    return (
            <AccountSubscriptionProvider store={subscriptionRequestSessionStore()} saveToServer={true}>
                <QuoteAndBuy />
            </AccountSubscriptionProvider>
    )
}

export default QuoteAndBuyProvider