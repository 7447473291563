import { Component, Show, createEffect, createSignal } from 'solid-js'
import { FormattedPrice } from '../../../../components/FormattedPrice/FormattedPrice'
import { getSubscription } from '../../../../components/Home/HomeProvider'
import { getQuote, isQuoteLoading } from '../../../../components/Quote/QuoteController'
import { Spinner } from '../../../../global/icons/Spinner/Spinner'
import styles from './QuoteFooter.module.css'
import { LetsGoButton } from './LetsGoButton'

export const QuoteFooter: Component<{showNewPremium: boolean}> = (props) => {

    const currentPremium = getSubscription().totalMonthlyPremium

    const [newPremium, setNewPremium] = createSignal(undefined)
    const [isLoadingPremium, setIsLoadingPremium] = createSignal(false)

    createEffect(() => {
        setNewPremium((props.showNewPremium) ? getQuote()?.totalMonthlyPremium : undefined)
        setIsLoadingPremium((props.showNewPremium) ? isQuoteLoading() : false)
    })

    return (
        <section class={styles.Footer}>
            <CurrentPrice value={currentPremium} />
            <Show when={props.showNewPremium && newPremium()}>
                <NewPrice value={newPremium()} isLoading={isLoadingPremium()} />
            </Show>
        </section>
    )
}

const CurrentPrice: Component<{ value: number }> = (props) => (
    <div class={styles.CurrentPrice}>
        <Price label="Current" value={props.value} />
    </div>
)

const NewPrice: Component<{ isLoading: boolean, value: number }> = (props) => (
    <div class={styles.NewPrice}>
        <Show when={!props.isLoading && props.value} fallback={<Spinner/>}>
            <Price label="New" value={props.value} />
            <LetsGoButton/>
        </Show>
    </div>
)

const Price: Component<{ value: number, label: string }> = (props) => (
    <span>
        <label>{props.label}:</label> <FormattedPrice price={props.value} />
    </span>
)