export {PaymentsService} from './src/PaymentsService'
export {PeachyApiService} from './src/PeachyApiService'
export type {RestApi} from './src/PeachyApiService'

        import * as me from '@peachy/payments-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/comp-kit/payments/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/payments-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    