import {API} from '@aws-amplify/api'
import {Company, toClass} from '@peachy/core-domain-pure'
import {LookupCompanyRequest, LookupCompanyResponse} from '@peachy/lookup-pure'

export class CompanyLookupService {
    constructor(readonly api: typeof API, readonly path: string = '/companies') {}

    async lookup(request: LookupCompanyRequest): Promise<LookupCompanyResponse> {
        const httpRequest = {
            headers: {},
            body: request,
        }
        return this.api.post(LookupApiName, `${this.path}/search`, httpRequest)
    }

    async fetch(companyId: string): Promise<Company> {
        const httpRequest = {
            headers: {},
            body: {companyId},
        }
        const response = await this.api.post(LookupApiName, `${this.path}/get`, httpRequest)
        return toClass(response, Company)
    }
}

export class CharityLookupService extends CompanyLookupService {
    constructor(readonly api: typeof API, readonly path: string = '/charities') {
        super(api, path)
    }
}

const LookupApiName = 'LookupApi'

