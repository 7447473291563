import styles from './BenefitIcons.module.css'

export default function () {
    const class2 = { fill: '#ffffff' }

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.9 89.96">
            <path class={styles.line} d="M53.24,45.26a10.91,10.91,0,0,1,0-6.81,6,6,0,0,1,9.67-3,11,11,0,0,1,3.85,5.63l-1,.3a9.9,9.9,0,0,0-3.47-5.12,5,5,0,0,0-8.15,2.49,10,10,0,0,0,0,6.18Z"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="57.46" y2="34.8"/>
            <rect class={styles.line} x="58.24" y="34.61" width="1" height="8.78" transform="translate(-8.83 18.86) rotate(-16.99)"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="53.75" y2="38.62"/>
            <rect class={styles.line} x="56.39" y="37.03" width="1" height="7.76" transform="translate(-9.7 62.72) rotate(-53.86)"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="53.72" y2="45.12"/>
            <rect class={styles.line} x="53.58" y="43.66" width="6.58" height="1" transform="translate(-10.43 18.58) rotate(-17.02)"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="57.38" y2="50.49"/>
            <rect class={styles.line} x="54.82" y="46.34" width="7.76" height="1" transform="matrix(0.34, -0.94, 0.94, 0.34, -5.32, 86.11)"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="62.58" y2="51.59"/>
            <rect class={styles.line} x="60.8" y="43" width="1" height="8.78" transform="translate(-11.17 19.97) rotate(-16.98)"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="66.29" y2="47.77"/>
            <rect class={styles.line} x="62.65" y="41.6" width="1" height="7.76" transform="translate(-10.83 69.65) rotate(-53.85)"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="66.32" y2="41.27"/>
            <rect class={styles.line} x="59.88" y="41.73" width="6.58" height="1" transform="translate(-9.6 20.34) rotate(-17.02)"/>
            <line class={styles.line} style={class2} x1="60.02" y1="43.19" x2="62.66" y2="35.9"/>
            <rect class={styles.line} x="57.46" y="39.05" width="7.76" height="1" transform="matrix(0.34, -0.94, 0.94, 0.34, 3.29, 83.78)"/>
            <path class={styles.line} d="M61.09,52c-3.18,0-6.4-2.75-7.64-6.83a10.5,10.5,0,0,1,0-6.61,6.14,6.14,0,0,1,3.91-4c3.63-1.11,7.76,1.85,9.21,6.6s-.33,9.5-3.95,10.61A5.27,5.27,0,0,1,61.09,52ZM59,35.35a4.8,4.8,0,0,0-1.29.19,5.21,5.21,0,0,0-3.26,3.39,9.61,9.61,0,0,0,0,6c1.29,4.22,4.86,6.88,8,5.93s4.57-5.14,3.28-9.36C64.53,37.84,61.71,35.35,59,35.35Z"/>
            <ellipse class={styles.line} cx="60.02" cy="43.19" rx="2.77" ry="3.69" transform="translate(-10 19.42) rotate(-16.98)"/>
            <path class={styles.line} d="M60.47,47.32a3.11,3.11,0,0,1-1.85-.65,4.87,4.87,0,0,1-1.73-2.52c-.67-2.21.18-4.44,1.91-5s3.67.84,4.35,3.05a4.81,4.81,0,0,1,0,3,2.92,2.92,0,0,1-1.88,1.91A2.53,2.53,0,0,1,60.47,47.32Zm-.9-7.25a1.59,1.59,0,0,0-.48.07c-1.2.37-1.75,2-1.24,3.72h0a3.86,3.86,0,0,0,1.36,2,1.81,1.81,0,0,0,3-.91,3.84,3.84,0,0,0,0-2.42A3.14,3.14,0,0,0,59.57,40.07Z"/>
            <path class={styles.line} d="M69.8,40.19a17.31,17.31,0,0,0-30.45-5.31,3.45,3.45,0,0,1,.91.44,16.23,16.23,0,0,1,8.19-5.69,16,16,0,0,1,4.76-.72A16.34,16.34,0,1,1,47.94,60.7a8.22,8.22,0,0,1-.09,1A17.33,17.33,0,0,0,69.8,40.19Z"/>
            <path class={styles.line} d="M37.68,50.26a7.82,7.82,0,0,0-.58,1.37,17.35,17.35,0,0,0,6.7,8.17c0-.39-.33-1.11-.37-1.49A15.92,15.92,0,0,1,37.68,50.26Z"/>
            <path class={styles.line} d="M46.84,63.92c-2,0-4.37-5.36-5.07-7.09,0,0-2-4.39-3.66-5.93a.5.5,0,0,1,0-.71.51.51,0,0,1,.71,0c1.85,1.68,3.82,6.09,3.9,6.28,1.51,3.73,3.49,6.76,4.2,6.47s.91-4.32-.35-11.23c-.34-1.83-2.09-6.8-3.14-9.77-.29-.82-.53-1.5-.67-1.92-.79-2.34-1.87-3.88-3.12-4.48a2.84,2.84,0,0,0-2.26-.09,8.66,8.66,0,0,0-2.72,1.66.5.5,0,0,1-.71,0,.49.49,0,0,1,0-.7A9.28,9.28,0,0,1,37,34.48a3.82,3.82,0,0,1,3,.13c1.52.72,2.75,2.43,3.64,5.06.14.42.37,1.09.66,1.91a103.64,103.64,0,0,1,3.18,9.92c.95,5.23,1.68,11.62-.28,12.35A1.12,1.12,0,0,1,46.84,63.92Z"/>
            <path class={styles.line} d="M39.06,68.37c-2.8,0-9.13-8.78-11.91-13.56a32.76,32.76,0,0,1-4-11c-.29-3,.58-4.86,2.56-5.6a5.65,5.65,0,0,1,2.23-.33A2.1,2.1,0,0,0,29,37.75a10.65,10.65,0,0,0,1.11-.81,9.87,9.87,0,0,1,2.34-1.51,9.16,9.16,0,0,1,3.25-.25.52.52,0,0,1,.45.55.49.49,0,0,1-.55.45,8,8,0,0,0-2.8.18,9.71,9.71,0,0,0-2.07,1.37,11.87,11.87,0,0,1-1.22.88,2.92,2.92,0,0,1-1.61.3,4.66,4.66,0,0,0-1.87.27c-1.55.57-2.16,2-1.92,4.57A31.77,31.77,0,0,0,28,54.31c4.46,7.65,10,13.47,11.09,13a.23.23,0,0,0,.15-.14c.28-.65-.6-2.62-1.36-4.36-.4-.91-.81-1.84-1.15-2.75-1.16-3.12-1.46-7.84,1.5-10.9l1.82-1.64a.5.5,0,0,1,.71,0,.5.5,0,0,1,0,.71l-1.8,1.62c-2.61,2.7-2.31,7-1.25,9.83.32.88.73,1.8,1.12,2.69,1,2.3,1.81,4.11,1.37,5.16a1.22,1.22,0,0,1-.72.69A1,1,0,0,1,39.06,68.37Z"/>
            <polygon class={styles.line} points="38.5 49.94 40.86 48.11 39.81 51.42 38.5 49.94"/>
        </svg>
    )
}