import { Auth } from '@aws-amplify/auth'
import { AccountSubscriptionDto, SubscriptionRequest } from '@peachy/core-domain-pure'
import { WelcomeAdminUserResponse } from '@peachy/iam-sme-pure'
import {SmeSubscriptionApi, SubscriptionResponse} from '@peachy/subscription-pure'

export type SubscriptionService = SmeSubscriptionApi

const getAuthUserHeader = async () => {
    const user = await Auth.currentAuthenticatedUser()
    return {
        'x-user-sub': user.username
    }
}

export function createSubscriptionService(api: SmeSubscriptionApi): SubscriptionService {

    return {
        async setupSmeSubscription(subRequest): Promise<SubscriptionResponse> {
            try {
                return await api.setupSmeSubscription(subRequest)
            } catch (e) {
                console.error(`Problem creating sme subscription`, e)
                throw Error(e.toString())
            } 
        },
        async saveDraftSmeSubscription(subRequest): Promise<SubscriptionResponse> {
            try {
                return await api.saveDraftSmeSubscription(subRequest)
            } catch (e) {
                console.error(`Problem saving a draft sme subscription`, e)
                throw Error(e.toString())
            } 
        },
        async getDraftSmeSubscription(): Promise<SubscriptionRequest> {
            try {
                const headers = await getAuthUserHeader()
                return await api.getDraftSmeSubscription(undefined, headers)
            } catch (e) {
                // do nothing as this is expected - will just return nothing
            } 
        },
        async getSmeSubscription(): Promise<AccountSubscriptionDto> {
            try {
                const headers = await getAuthUserHeader()
                return await api.getSmeSubscription(undefined, headers)
            } catch (e) {
                // do nothing as this is expected - will just return nothing
            } 
        },
        async welcomeAdminUser(welcomeRequest): Promise<WelcomeAdminUserResponse> {
            try {
                return await api.welcomeAdminUser(welcomeRequest)
            } catch (e) {
                // do nothing as this is expected - will just return nothing
            } 
        }
    }
}