import { JSXElement, ParentComponent } from 'solid-js'

export const Page: ParentComponent<{ header?: JSXElement }> = (props) => {

    return (
        <div>
            {props.header}
            <main>
                <div class="container">
                    {props.children}
                </div>
            </main>
        </div>
    )
}